import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

type Instances = Record<string, AxiosInstance>;

const instances: Instances = {
  mapApiInstance: axios.create({
    baseURL: import.meta.env.VITE_MAP_API_HOSTNAME,
  }),
  devApiInstance: axios.create({
    baseURL: import.meta.env.VITE_DEV_API_HOSTNAME,
  }),
  swrClient: axios.create(),
};

Object.keys(instances).forEach((key) => {
  instances[key].interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  instances[key].interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error.response.status;
      const response = error.response.data;

      return Promise.reject({
        error,
        statusCode: code,
        response,
      });
    },
  );
});

const swrFetcher = async <T = any>(url: string, token: string) => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url,
  };
  return await instances.swrClient<T>(config).then((res) => res.data);
};

const swrFetcherWithToken = async <T = any>(url: string, token: string) => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url,
    headers: { Authorization: `Bearer ${token}`, timestamp: Math.round(Date.now() / 1000) },
  };
  return await instances.swrClient<T>(config).then((res) => res.data);
};

const { mapApiInstance, devApiInstance } = instances;
export { mapApiInstance, devApiInstance, swrFetcher, swrFetcherWithToken };

import { isBefore } from 'date-fns';
import parseJSON from 'date-fns/parseJSON';
import { MapData } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';
import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';

export interface SortingRule {
  key: MAP_MANAGER_SORT_KEY;
  order: SORT_ORDER;
}

export const sortMap = (maps: MapData[], sorting: SortingRule) => {
  if (!maps.length) return [];
  const sortedMap = maps.sort((a: MapData, b: MapData) => {
    if (sorting.key === MAP_MANAGER_SORT_KEY.CREATED_DATE) {
      const aVal = parseJSON(a.reg_dt_num);
      const bVal = parseJSON(b.reg_dt_num);
      if (aVal === bVal) return 0;
      return isBefore(aVal, bVal) ? -1 : 1;
    }

    if (sorting.key === MAP_MANAGER_SORT_KEY.LAST_UPDATE_DATE) {
      const aVal = parseJSON(a.update_dt_num);
      const bVal = parseJSON(b.update_dt_num);
      if (aVal === bVal) return 0;
      return isBefore(aVal, bVal) ? -1 : 1;
    }

    const aVal = a.map_name.toUpperCase();
    const bVal = b.map_name.toUpperCase();
    if (aVal === bVal) return 0;
    return aVal < bVal ? -1 : 1;
  });

  return sorting.order === SORT_ORDER.DESC ? sortedMap.reverse() : sortedMap;
};

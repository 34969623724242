import React from 'react';
import { TFunction } from 'i18next';
import { MapGroup } from '../../types';
import { useTranslation } from 'react-i18next';
import Modal from '@/components/Modal';
import { Button, HStack, Highlight, Text } from '@chakra-ui/react';

interface DeleteMapGroupProps {
  groupData?: MapGroup;
  handleClose: () => void;
  handleConfirm: () => void;
  error?: string;
  t: TFunction;
  show: boolean;
}

const DeleteMapGroup: React.FC<DeleteMapGroupProps> = (props) => {
  const { t } = useTranslation();
  const handleConfirm = () => {
    props.handleConfirm();
    props.handleClose();
  };

  if (!props.groupData) return <></>;

  const deleteConfirmationMessage = t('delete_map_group_confirmation_message').replace(
    /({val})/gm,
    props.groupData?.group_name,
  );

  return (
    <Modal
      footer={
        <HStack>
          <Button
            colorScheme="gray"
            size="sm"
            variant="ghost"
            w="100px"
            onClick={props.handleClose}>
            {t('cancel')}
          </Button>

          <Button colorScheme="red" size="sm" variant="solid" w="100px" onClick={handleConfirm}>
            {t('delete')}
          </Button>
        </HStack>
      }
      show={props.show}
      title={t('delete_map_group')}
      onClose={props.handleClose}>
      <Text>
        <Highlight query={props.groupData.group_name} styles={{ fontWeight: 'bold' }}>
          {deleteConfirmationMessage}
        </Highlight>
      </Text>
      <Text color="red.500" fontSize="xs">
        {t('not_reversible_action')}
      </Text>
    </Modal>
  );
};

export default DeleteMapGroup;

import { atom } from 'recoil';
import localStorageEffect from '@/store/atoms/effects/localStorage';
import { PreferenceState } from './types';

const preferenceState = atom<PreferenceState>({
  key: 'preferences',
  default: {},
  effects: [localStorageEffect()],
});

export default preferenceState;

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  InputElementProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { ErrorMessage, ErrorMessageProps } from '../ErrorMessage';
export interface InputProps extends InputElementProps {
  label?: string;
  error?: ErrorMessageProps['error'];
  type: HTMLInputElement['type'];
  helperText?: string;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  autoComplete?: string;
}

const Input: React.FC<InputProps> = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, type, helperText, id, register, disabled, autoComplete, ...props }, ref) => {
    const { t } = useTranslation();
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    return (
      <FormControl isInvalid={!!error}>
        <FormLabel>{label}</FormLabel>
        <InputGroup>
          {type === 'password' ? (
            <>
              <ChakraInput
                {...register}
                {...props}
                type={show ? 'text' : 'password'}
                id={id}
                disabled={disabled}
                autoComplete={autoComplete}
              />
              <InputRightElement width="3rem">
                <Button h="1.75rem" size="md" variant="ghost" onClick={handleClick}>
                  {show ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </>
          ) : (
            <ChakraInput
              {...register}
              {...props}
              type={type}
              id={id}
              disabled={disabled}
              autoComplete={autoComplete}
            />
          )}
        </InputGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <ErrorMessage error={error} />
      </FormControl>
    );
  },
);

export default Input;

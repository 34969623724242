import { createIcon } from '@chakra-ui/react';

const MoreIcon = createIcon({
  displayName: 'MoreIcon',
  viewBox: '0 0 30 22',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <text
        id="_"
        data-name="…"
        transform="translate(0 26)"
        fill="#707070"
        font-size="30"
        fontFamily="HiraKakuProN-W6, Hiragino Kaku Gothic ProN">
        <tspan x="0" y="0">
          …
        </tspan>
      </text>
    </svg>
  ),
});

export default MoreIcon;

import { AlertStatus, useToast } from '@chakra-ui/react';

const useNotification = () => {
  const toast = useToast();

  const baseConfiguration = {
    isClosable: true,
  };

  const displayToast = (title: string, status: AlertStatus) => {
    toast({
      ...baseConfiguration,
      title,
      status,
    });
  };

  const success = (title: string) => displayToast(title, 'success');

  const error = (title: string) => displayToast(title, 'error');

  const warning = (title: string) => displayToast(title, 'warning');

  const info = (title: string) => displayToast(title, 'info');

  return { success, error, warning, info };
};

export default useNotification;

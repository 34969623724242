import * as ReactDOMClient from 'react-dom/client';
import AppRoot from './AppRoot';

// const config = {
//   url: 'https://unleash-proxy.pretiaar.com/proxy',
//   clientKey: 'inSnP6zo3HhYjn1A4aK4',
//   refreshInterval: 150,
//   appName: 'developer-console',
// };

// const AppRoot: React.FC<unknown> = () => {
//   return (
//     <React.StrictMode>
//       <FlagProvider config={config}>
//         <RecoilRoot>
//           <BrowserRouter basename={import.meta.env.VITE_BASE_URL}>
//             <App />
//           </BrowserRouter>
//         </RecoilRoot>
//       </FlagProvider>
//     </React.StrictMode>
//   );
// };

// A workaround to prevent crash on page translate by google chrome
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this,
        );
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}
// end

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);
root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

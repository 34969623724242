import { MapManagerActionType, MAP_MANAGER_ACTION_NAME } from './actions';
import { MapManagerPageState } from './MapListing';

const reducer = (state: MapManagerPageState, action: MapManagerActionType) => {
  switch (action.type) {
    case MAP_MANAGER_ACTION_NAME.SELECT_MAP:
      return { ...state, selectedMaps: [...state.selectedMaps, action.payload] };

    case MAP_MANAGER_ACTION_NAME.UNSELECT_MAP:
      return {
        ...state,
        selectedMaps: [...state.selectedMaps].filter((m) => m !== action.payload.map_key),
      };

    case MAP_MANAGER_ACTION_NAME.CLEAR_SELECTED_MAP:
      return { ...state, selectedMaps: [] };

    case MAP_MANAGER_ACTION_NAME.SHOW_MODAL:
      return { ...state, modal: { show: true, name: action.payload } };

    case MAP_MANAGER_ACTION_NAME.HIDE_MODAL:
      return { ...state, modal: { show: false } };

    case MAP_MANAGER_ACTION_NAME.PROCESSING_REQUEST:
      return { ...state, processing: true };

    case MAP_MANAGER_ACTION_NAME.FINISHED_REQUEST:
      return { ...state, processing: false };

    case MAP_MANAGER_ACTION_NAME.SELECT_MAP_GROUP:
      return { ...state, selectedMapGroup: action.payload };

    case MAP_MANAGER_ACTION_NAME.CHANGE_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;

import { ActionType } from '@/types/common';
import { MapManagerPageState, MAP_MANAGER_MODAL } from './MapListing';
import { MapData } from './types';

export enum MAP_MANAGER_ACTION_NAME {
  SELECT_MAP = 'SELECT_MAP',
  UNSELECT_MAP = 'UNSELECT_MAP',
  CLEAR_SELECTED_MAP = 'CLEAR_SELECTED_MAP',
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
  PROCESSING_REQUEST = 'PROCESSING_REQUEST',
  FINISHED_REQUEST = 'FINISHED_REQUEST',
  DISPLAY_MODAL_ERROR_MESSAGE = 'DISPLAY_MODAL_ERROR_MESSAGE',
  SELECT_MAP_GROUP = 'SELECT_MAP_GROUP',
  CHANGE_FILTER = 'FILTER_CHANGE',
}

type SelectMap = ActionType<MAP_MANAGER_ACTION_NAME.SELECT_MAP, MapData>;
type UnselectMap = ActionType<MAP_MANAGER_ACTION_NAME.UNSELECT_MAP, MapData>;
type ClearSelectedMap = ActionType<MAP_MANAGER_ACTION_NAME.CLEAR_SELECTED_MAP>;
type ShowModal = ActionType<MAP_MANAGER_ACTION_NAME.SHOW_MODAL, MAP_MANAGER_MODAL>;
type HideModal = ActionType<MAP_MANAGER_ACTION_NAME.HIDE_MODAL>;
type ProcessingRequest = ActionType<MAP_MANAGER_ACTION_NAME.PROCESSING_REQUEST>;
type FinishedRequest = ActionType<MAP_MANAGER_ACTION_NAME.FINISHED_REQUEST>;
type SelectMapGroup = ActionType<MAP_MANAGER_ACTION_NAME.SELECT_MAP_GROUP, string>;
type ChangeFilter = ActionType<
  MAP_MANAGER_ACTION_NAME.CHANGE_FILTER,
  Partial<MapManagerPageState['filter']>
>;

const actions = {
  selectMap: (payload: MapData): SelectMap => ({
    type: MAP_MANAGER_ACTION_NAME.SELECT_MAP,
    payload,
  }),

  unselectMap: (payload: MapData): UnselectMap => ({
    type: MAP_MANAGER_ACTION_NAME.UNSELECT_MAP,
    payload,
  }),

  clearSelectedMap: (): ClearSelectedMap => ({
    type: MAP_MANAGER_ACTION_NAME.CLEAR_SELECTED_MAP,
  }),

  showModal: (payload: MAP_MANAGER_MODAL): ShowModal => ({
    type: MAP_MANAGER_ACTION_NAME.SHOW_MODAL,
    payload,
  }),

  hideModal: (): HideModal => ({
    type: MAP_MANAGER_ACTION_NAME.HIDE_MODAL,
  }),

  processingRequest: (): ProcessingRequest => ({
    type: MAP_MANAGER_ACTION_NAME.PROCESSING_REQUEST,
  }),

  finishedRequest: (): FinishedRequest => ({
    type: MAP_MANAGER_ACTION_NAME.FINISHED_REQUEST,
  }),

  selectMapGroup: (payload: string): SelectMapGroup => ({
    type: MAP_MANAGER_ACTION_NAME.SELECT_MAP_GROUP,
    payload,
  }),

  changeFilter: (payload: Partial<MapManagerPageState['filter']>): ChangeFilter => ({
    type: MAP_MANAGER_ACTION_NAME.CHANGE_FILTER,
    payload,
  }),
};

export type MapManagerActionType =
  | ReturnType<typeof actions.selectMap>
  | ReturnType<typeof actions.unselectMap>
  | ReturnType<typeof actions.clearSelectedMap>
  | ReturnType<typeof actions.showModal>
  | ReturnType<typeof actions.hideModal>
  | ReturnType<typeof actions.processingRequest>
  | ReturnType<typeof actions.finishedRequest>
  | ReturnType<typeof actions.selectMapGroup>
  | ReturnType<typeof actions.changeFilter>;

export default actions;

import useURLGenerator from '@/hooks/use-url-generator';
import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  CONSOLE_ROOT_PATH,
  LOGIN_PAGE_PATH,
  TERM_PAGE_PATH,
  VERIFICATION_PAGE_PATH,
} from '@/routes/path';
import { userState } from '@/store/atoms/users';

const AuthenticationRoute: React.FC = () => {
  const { generateURL } = useURLGenerator();
  const { pathname, search } = useLocation();
  let next = `${pathname}${search}`;
  if (next === CONSOLE_ROOT_PATH) next = '';
  const { isAuthenticated, isVerified, isAcceptedTerms, token } = useRecoilValue(userState);

  if (isAuthenticated) {
    if (!isVerified) {
      return (
        <Navigate
          to={{ pathname: generateURL(VERIFICATION_PAGE_PATH, { token }), search: `?next=${next}` }}
        />
      );
    }
    if (isAcceptedTerms) {
      return <Outlet />;
    }
    return <Navigate to={{ pathname: TERM_PAGE_PATH, search: `?next=${next}` }} />;
  }

  return <Navigate to={{ pathname: LOGIN_PAGE_PATH, search: `?next=${next}` }} />;
};

export default AuthenticationRoute;

import { userState } from '@/store/atoms/users';
import { SERVER_SIDE_ERROR } from '@/types/errors';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import { SWRConfig as OriginalSWRConfig, SWRConfiguration } from 'swr';
import { useNavigate, useParams } from 'react-router';
import { PENDING_TEAM_PAGE_PATH, TEAM_ID_KEY } from '@/routes/path';
import useURLGenerator from '@/hooks/use-url-generator';

interface SWRConfigProps {
  children: React.ReactElement;
}

const NO_RETRY_ERROR = [
  SERVER_SIDE_ERROR.EXPIRED_TOKEN,
  SERVER_SIDE_ERROR.INVALID_TOKEN,
  SERVER_SIDE_ERROR.INVITATION_PENDING,
];
const RETRY_TIME_INTERVAL = 5000;

const SWRConfig: React.FC<SWRConfigProps> = ({ children }) => {
  const resetUser = useResetRecoilState(userState);
  const navigate = useNavigate();
  const { generateURL } = useURLGenerator();
  const { teamId } = useParams<{ teamId: string }>();

  const handleErrorRetry: SWRConfiguration['onErrorRetry'] = (
    error,
    key,
    { errorRetryInterval = 5 },
    revalidate,
    { retryCount = 0 },
  ) => {
    const errorMessage = error.error?.response?.data?.error?.trim();
    if (NO_RETRY_ERROR.includes(errorMessage)) return;
    setTimeout(() => revalidate({ retryCount }), RETRY_TIME_INTERVAL);
  };

  const handleError: SWRConfiguration['onError'] = (err) => {
    const errorMessage = err.error?.response?.data?.error?.trim();
    switch (errorMessage) {
      case SERVER_SIDE_ERROR.EXPIRED_TOKEN:
        resetUser();
        break;
      case SERVER_SIDE_ERROR.INVITATION_PENDING:
        navigate(generateURL(PENDING_TEAM_PAGE_PATH, { [TEAM_ID_KEY]: teamId! }));
        break;
    }
  };

  return (
    <OriginalSWRConfig
      value={{
        refreshInterval: 20000,
        revalidateOnMount: true,
        onErrorRetry: handleErrorRetry,
        onError: handleError,
      }}>
      {children}
    </OriginalSWRConfig>
  );
};

export default SWRConfig;

import { Button, HStack, Highlight, Text } from '@chakra-ui/react';
import Modal from '@/components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MapData } from '../../types';

interface CopyMapProps {
  map?: MapData;
  onClose: () => void;
  onConfirm: () => void;
  show: boolean;
}

const CopyMap: React.FC<CopyMapProps> = ({ map, onClose, onConfirm, show }) => {
  const { t } = useTranslation();

  if (!map) return <></>;
  return (
    <Modal
      footer={
        <HStack>
          <Button color="gray" size="sm" variant="ghost" w="100px" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button colorScheme="pretia" size="sm" variant="solid" w="100px" onClick={onConfirm}>
            {t('copy')}
          </Button>
        </HStack>
      }
      show={show}
      title={t('copy_map')}
      onClose={onClose}>
      <Text fontSize="md">
        <Highlight query={`｢${map.map_name}｣`} styles={{ fontWeight: 'bold' }}>
          {t('copy_map_confirmation_message').replace(/({val})/gm, `｢${map.map_name}｣`)}
        </Highlight>
      </Text>
    </Modal>
  );
};

export default CopyMap;

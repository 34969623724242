import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import Modal from '@/components/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MapData } from '../../types';

interface DeleteMapProps {
  map?: MapData;
  onClose: () => void;
  onConfirm: () => void;
  show: boolean;
}

const DeleteMap: React.FC<DeleteMapProps> = (props) => {
  const { t } = useTranslation();
  const handleConfirm = () => {
    props.onConfirm();
    props.onClose();
  };

  if (!props.map) return <></>;

  const mapNames = props.map.map_name;
  const isBeingUse = props.map.assigned_app_keys.length !== 0;

  if (isBeingUse) {
    return (
      <Modal
        footer={
          <HStack>
            <Button colorScheme="blue" size="sm" variant="solid" w="100px" onClick={props.onClose}>
              {t('close')}
            </Button>
          </HStack>
        }
        show={props.show}
        title={t('delete_map')}
        onClose={props.onClose}>
        <Box>
          {t('delete_map_error_map_in_use')}

          <Text color="red.500" fontSize="xs">
            {t('map_in_use')}
          </Text>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      footer={
        <HStack>
          <Button colorScheme="gray" size="sm" variant="outline" w="100px" onClick={props.onClose}>
            {t('cancel')}
          </Button>

          <Button colorScheme="red" size="sm" variant="solid" w="100px" onClick={handleConfirm}>
            {t('delete')}
          </Button>
        </HStack>
      }
      show={props.show}
      title={t('delete_map')}
      onClose={props.onClose}>
      <Box>
        {t('delete_map_confirmation_message')} <br />
        <Text color="red.500" fontSize="xs">
          {t('not_reversible_action')}
        </Text>
      </Box>
    </Modal>
  );
};

export default DeleteMap;

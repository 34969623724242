import { ButtonProps, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    pretia: {
      DEFAULT: '#33ADCB',
      '50': '#C5E8F0',
      '100': '#B5E1EC',
      '200': '#94D4E4',
      '300': '#74C7DC',
      '400': '#53BAD4',
      '500': '#33ADCB',
      '600': '#28879E',
      '700': '#1C6171',
      '800': '#113A44',
      '900': '#061418',
    },
    blue: {
      DEFAULT: '#3C74C3',
      '50': '#EBF1F9',
      '100': '#C8D8EE',
      '200': '#A5BFE3',
      '300': '#82A6D9',
      '400': '#5F8DCE',
      '500': '#3C74C3',
      '600': '#305C9C',
      '700': '#244575',
      '800': '#182E4E',
      '900': '#0C1727',
    },
    teal: {
      DEFAULT: '#4DA1B2',
      '50': '#EDF6F7',
      '100': '#CDE5E9',
      '200': '#ADD4DB',
      '300': '#8DC3CE',
      '400': '#6DB2C0',
      '500': '#4DA1B2',
      '600': '#3E818E',
      '700': '#2E616B',
      '800': '#1F4047',
      '900': '#0F2024',
    },
    gray: {
      DEFAULT: '#808080',
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
  },
  components: {
    Button: {
      variants: {
        'invert-solid': (props: ButtonProps) => ({
          ...theme.components.Button.variants.outline(props),
          backgroundColor: 'white',
          color: `${props.colorScheme}.500`,
          borderColor: 'white',
          _hover: {
            backgroundColor: `${props.colorScheme}.500`,
            color: 'white',
            borderColor: `${props.colorScheme}.500`,
          },
        }),
      },
      defaultProps: {
        size: 'md',
        colorScheme: 'blue',
      },
    },
    Link: {
      variants: {
        'white-link': {
          color: 'gray.200',
          _hover: {
            color: 'white',
          },
        },
        'blue-link': {
          color: 'blue.500',
          _hover: {
            color: 'blue.300',
          },
        },
        unstyled: {},
      },
      baseStyle: {
        textDecoration: 'none',
        _hover: {
          textDecoration: 'none',
        },
      },
      defaultProps: {
        variant: 'blue-link',
      },
    },
    Divider: {
      baseStyle: {
        color: 'gray.200',
      },
    },
    Spinner: {
      baseStyle: {
        color: 'blue.500',
      },
      defaultProps: {
        size: 'xl',
      },
    },
  },
});

export default theme;

import { MapManagerPageState } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/MapListing';
import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';
import { atom } from 'recoil';

const initialState: MapManagerPageState = {
  processing: false,
  selectedMaps: [],
  modal: {
    show: false,
  },
  showMergingView: false,
};

export const mapManagerState = atom<MapManagerPageState>({
  key: 'map-manager',
  default: initialState,
});

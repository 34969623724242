import React from 'react';
import useMapFilter from '@/hooks/map/use-map-filter';
import { Box, Button, ButtonGroup, Stack, Text } from '@chakra-ui/react';
import MapListingFilter from '@/components/MapListingTable/MapListingFilter';
import { useTranslation } from 'react-i18next';
import useFetchTeamApps from '@/hooks/fetcher/use-fetch-team-apps';
import useFetchTeamMapGroups from '@/hooks/fetcher/use-fetch-team-map-groups';
import MapListingTable from '@/components/MapListingTable';
import CopyMap from '../Dashboard/MapManagePage/MapListing/Modal/CopyMap';
import { MAP_MANAGER_MODAL } from '../Dashboard/MapManagePage/MapListing';
import useMapOperations from '@/hooks/use-map-operations';
import DeleteMap from '../Dashboard/MapManagePage/MapListing/Modal/DeleteMap';
import ExportMap from '../Dashboard/MapManagePage/MapListing/Modal/ExportMap';
import MergeMap from '../Dashboard/MapManagePage/MapListing/Modal/MergeMap';
import MapGroup from '../Dashboard/MapManagePage/MapListing/Modal/MapGroup';
import DeleteMapGroup from '../Dashboard/MapManagePage/MapListing/Modal/DeleteMapGroup';

const MapManagementPage: React.FC = () => {
  const { data: mapGroups } = useFetchTeamMapGroups();
  const { setters, filter } = useMapFilter();
  const { state, events, operations } = useMapOperations();
  const { t } = useTranslation();

  React.useEffect(() => {
    events.resetState();
  }, []);

  return (
    <Stack gap="4">
      <Stack direction="row" justify="space-between">
        <Text fontSize="xs" color="blue.500" w="60%" h="40px">
          {state.showMergingView
            ? '複数のマップをひとつのマップに統合します。合成したいマップを複数選択してください。マップは元のマップとは別の新たなマップとして保存されます。マップの合成は、マップ同士に重なり合う部分がなければできません。'
            : ''}
        </Text>
        {state.showMergingView ? (
          <ButtonGroup>
            <Button colorScheme="blue" size="sm" variant="ghost" onClick={events.hideMergingView}>
              {t('cancel')}
            </Button>
            <Button
              colorScheme="blue"
              size="sm"
              variant="solid"
              onClick={events.showMergeMapModal}
              isDisabled={state.selectedMaps.length < 2}>
              {t('merge')}
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button colorScheme="blue" size="sm" variant="outline" onClick={events.showMergingView}>
              {t('merge')}
            </Button>
            <Button
              colorScheme="blue"
              size="sm"
              variant="outline"
              onClick={events.displayCreateMapGroupModal}>
              {t('create_map_group')}
            </Button>
          </ButtonGroup>
        )}
      </Stack>
      <Box w="60%">
        <MapListingFilter
          handleKeywordChange={setters.setKeyword}
          handleSortKeySelect={setters.setSortKey}
          handleSortOrderSelect={setters.setSortOrder}
        />
      </Box>
      <MapListingTable
        filter={filter}
        groups={mapGroups}
        withMapCheckboxSelection={state.showMergingView}
        withGroupMenuOption={!state.showMergingView}
      />
      <Box></Box>
      <CopyMap
        map={state.selectedMaps[0]}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.COPY_MAP_MODAL &&
          !!state.selectedMaps[0]
        }
        onClose={events.hideModal}
        onConfirm={events.copyMap}
      />
      <DeleteMap
        map={state.selectedMaps[0]}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.DELETE_MAP_MODAL &&
          !!state.selectedMaps[0]
        }
        onClose={events.hideModal}
        onConfirm={events.deleteMap}
      />
      <ExportMap
        createShareLink={events.createShareLink}
        map={state.selectedMaps[0]}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.EXPORT_MAP_MODAL &&
          !!state.selectedMaps[0]
        }
        t={t}
        onClose={events.hideModal}
      />

      <MapGroup
        handleClose={events.hideModal}
        handleConfirm={operations.createMapGroup}
        show={state.modal.show && state.modal.name === MAP_MANAGER_MODAL.CREATE_MAP_GROUP_MODAL}
        t={t}
      />

      <MapGroup
        data={state.selectedMapGroup}
        handleClose={events.hideModal}
        handleConfirm={operations.editMapGroup}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.EDIT_MAP_GROUP_MODAL &&
          !!state.selectedMapGroup
        }
        t={t}
      />

      <DeleteMapGroup
        groupData={state.selectedMapGroup}
        handleClose={events.hideModal}
        handleConfirm={operations.deleteMapGroup}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.DELETE_MAP_GROUP_MODAL &&
          !!state.selectedMapGroup
        }
        t={t}
      />
    </Stack>
  );
};

export default MapManagementPage;

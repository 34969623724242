import { FormErrorMessage } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ErrorMessageObject {
  key: string;
  replaceKey?: Record<string, string | number>;
  label?: string;
  conjunction?: string;
}

export interface ErrorMessageProps {
  error?: ErrorMessageObject | string;
  errorSrc?: string;
}

const isErrorMessageObject = (error: unknown): error is ErrorMessageObject => {
  return typeof error !== 'string' && typeof error === 'object' && error !== null && 'key' in error;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, errorSrc }) => {
  const { t, i18n } = useTranslation();
  let message: string;
  if (isErrorMessageObject(error)) {
    const { key, replaceKey, label, conjunction } = error;
    message = t(key);
    if (replaceKey) {
      Object.entries(replaceKey).forEach(([key, val]) => {
        message = message.replace(`\${${key}}`, val as string);
      });
    }

    if (label) {
      message = message.replace(
        `\${label}`,
        i18n.language === 'jp' && conjunction ? `${t(label)}${conjunction}` : t(label),
      );
    } else {
      message = message.replace(`\${label}`, '');
    }
  } else if (error) {
    const errorKey = error;
    message = t(errorKey);
    if (!i18n.exists(errorKey) && errorSrc === 'API') {
      message = 'SOMETHING_WENT_WRONG';
    }
  } else {
    message = '';
  }

  return <FormErrorMessage>{message}</FormErrorMessage>;
};

export { ErrorMessage };

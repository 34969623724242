import { Box, Center, Spinner, Stack, Text } from '@chakra-ui/react';
import Fuse from 'fuse.js';
import { GroupWithMaps, MapData } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';
import React from 'react';
import { TFunction } from 'i18next';
import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';
import { sortMap } from '@/utils/map/sorting';
import MapListingGroup from './MapListingGroup';
import MapListingItem from './MapListingItem';
import { useTranslation } from 'react-i18next';
import NoMapData from './NoMapData';
import useFetchTeamMapGroups from '@/hooks/fetcher/use-fetch-team-map-groups';

interface MapListingTableProps {
  groups?: GroupWithMaps[];
  filter: {
    keyword?: string;
    sortKey: MAP_MANAGER_SORT_KEY;
    sortOrder: SORT_ORDER;
  };
  withMapCheckboxSelection?: boolean;
  withGroupMenuOption?: boolean;
  onlyDisplayProcessedMaps?: boolean;
}

const MapListingTable: React.FC<MapListingTableProps> = ({
  groups = [],
  filter = { sortKey: MAP_MANAGER_SORT_KEY.NAME, sortOrder: SORT_ORDER.ASC },
  withMapCheckboxSelection,
  withGroupMenuOption,
  onlyDisplayProcessedMaps = false,
  ...props
}) => {
  const { isLoading, error } = useFetchTeamMapGroups();
  const { t } = useTranslation();
  const fuseOption: Fuse.IFuseOptions<MapData> = {
    includeScore: false,
    keys: ['map_name', 'map_key'],
  };

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (error) return <Text align="center">{t('SOMETHING_WENT_WRONG')}</Text>;
  if (!groups?.length && !isLoading) return <NoMapData />;

  return (
    <Stack w="full">
      {groups.map((g) => {
        const maps = onlyDisplayProcessedMaps ? g.maps.filter((m) => m.map_availability) : g.maps;
        const mapList = new Fuse(maps, fuseOption);
        const filteredMaps = mapList.search(filter.keyword || '');
        const sortedMaps = sortMap(filter.keyword ? filteredMaps.map((m) => m.item) : maps, {
          key: filter.sortKey,
          order: filter.sortOrder,
        });
        if (!sortedMaps.length && filter.keyword) return <></>;
        return (
          <MapListingGroup key={g.group_key} group={g} withMenus={withGroupMenuOption}>
            {sortedMaps.map((m, i) => (
              <MapListingItem
                key={i + m.map_name}
                map={m}
                withCheckboxSelection={withMapCheckboxSelection}
              />
            ))}
            {!sortedMaps.length && <Box textAlign="center">{t('no_map_belong_to_this_group')}</Box>}
          </MapListingGroup>
        );
      })}
    </Stack>
  );
};

export default MapListingTable;

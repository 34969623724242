/* eslint-disable @typescript-eslint/no-explicit-any */
export const mixed = {
  required: ({ label, path }: any) => ({
    key: 'mixed_required',
    label: label || path,
    conjunction: 'は',
  }),
};

export const string = {
  email: () => ({ key: 'string_email' }),
  min: ({ min, label, path }: any) => ({
    key: 'string_min',
    replaceKey: { min },
    label: label || path,
    conjunction: 'は',
  }),
  max: ({ max, label, path }: any) => ({
    key: 'string_max',
    replaceKey: { max },
    label: label || path,
    conjunction: 'は',
  }),
};

export const number = {
  min: ({ min }: any) => ({ key: 'fieldTooShort', replaceKey: { min } }),
  max: ({ max }: any) => ({ key: 'fieldTooLong', replaceKey: { max } }),
};

export default {
  mixed,
  string,
  number,
};

import { AtomEffect, DefaultValue } from 'recoil';

import { isBefore, parseISO } from 'date-fns';

const getStateFromLocalStorage = (key: string) => {
  const savedState = localStorage.getItem(key);
  if (!savedState) return;
  const { value, expiry } = JSON.parse(savedState);
  if (expiry && isBefore(parseISO(expiry), Date.now())) {
    console.log(key, expiry && isBefore(parseISO(expiry), Date.now()));
    localStorage.removeItem(key);
    return null;
  }
  return value;
};

export interface LocalStorageEffectOptions {
  expiry?: Date;
}

const localStorageEffect =
  <T>(options?: LocalStorageEffectOptions): AtomEffect<T> =>
  ({ node, setSelf, onSet }) => {
    const prefix = 'arc:';
    const storageKey = `${prefix}${node.key}`;
    const value = getStateFromLocalStorage(storageKey);
    if (value) setSelf(value);

    onSet((newValue: T | DefaultValue, _: T | DefaultValue, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(storageKey)
        : localStorage.setItem(
            storageKey,
            JSON.stringify({ value: newValue, expiry: options?.expiry }),
          );
    });
  };

export default localStorageEffect;

import useSWR from 'swr';
import { swrFetcherWithToken } from '@/services/request/client';
import { userState } from '@/store/atoms/users';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  GetTeamMapGroupResponse,
  GroupWithMaps,
} from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';

const useFetchTeamMapGroups = () => {
  // required data
  const { token } = useRecoilValue(userState);
  const { teamId } = useParams<{ teamId: string; appKey: string }>();

  // url builder
  const baseURL = import.meta.env.VITE_MAP_API_HOSTNAME;
  const path = ['group', 'extended-groups', teamId];
  const endpoint = `${baseURL}/${path.join('/')}`;

  // request setup
  return useSWR<GroupWithMaps[], any, [string, string]>([endpoint, token], ([url, token]) =>
    swrFetcherWithToken<GetTeamMapGroupResponse>(url, token).then((res) => res.groups),
  );
};

export default useFetchTeamMapGroups;

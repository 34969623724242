import useSWR from 'swr';
import { GetRolesResponse } from '@/pages/DeveloperConsolePage/Setting/types';
import { swrFetcherWithToken } from '@/services/request/client';
import { userState } from '@/store/atoms/users';
import { Role } from '@/types/roles';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const useFetchUserRoles = () => {
  // required data
  const { token } = useRecoilValue(userState);
  const { teamId } = useParams<{ teamId: string }>();

  // url builder
  const baseURL = import.meta.env.VITE_DEV_API_HOSTNAME;
  const path = ['org', teamId, 'roles'];
  const endpoint = `${baseURL}/${path.join('/')}`;

  // request setup
  return useSWR<Role, any, [string, string]>([endpoint, token], ([url, token]) =>
    swrFetcherWithToken<GetRolesResponse>(url, token).then((res) => res.roles),
  );
};

export default useFetchUserRoles;

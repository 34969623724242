import {
  MapData,
  MapGroup,
  MapMergingResponse,
} from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';
import {
  deleteMap as deleteMapRequest,
  deleteMapGroup as deleteMapGroupRequest,
  copyMap as copyMapRequest,
  createMapGroup as createMapGroupRequest,
  editMapGroup as editMapGroupRequest,
  assignMapToApp as assignMapToAppRequest,
  forkMap as forkMapRequest,
  shareMap as shareMapRequest,
  shareMap,
} from '@/services/request';
import { userState } from '@/store/atoms/users';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import useURLGenerator from '@/hooks/use-url-generator';
import useNotification from '../use-notification';
import { useTranslation } from 'react-i18next';
import { mapManagerState } from '@/store/atoms/map-page';
import { MAP_MANAGER_MODAL } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/MapListing';
import { AxiosError, isAxiosError } from 'axios';
import { CLONE_MAP_PAGE_PATH, MAP_SHARE_TOKEN_KEY } from '@/routes/path';
import useFetchTeamMapGroups from '../fetcher/use-fetch-team-map-groups';
import { useLocation, useParams } from 'react-router-dom';
import { MapGroupRequestPayload, MapMergingPayload } from '@/services/request/types';
import { UseFormSetError } from 'react-hook-form';
import { MapGroupFormValues } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/MapListing/Modal/MapGroup';
import React from 'react';

const useMapOperations = () => {
  const { mutate } = useFetchTeamMapGroups();
  const { token } = useRecoilValue(userState);
  const notifications = useNotification();
  const { t } = useTranslation();
  const { generateURL } = useURLGenerator();
  const { teamId } = useParams<{ teamId: string }>();
  const resetState = useResetRecoilState(mapManagerState);

  const [state, setState] = useRecoilState(mapManagerState);

  const onSelectMap = (map: MapData, isMultiSelect = false) => {
    if (state.selectedMaps.find((m) => m.map_key === map.map_key)) return;
    if (!isMultiSelect) {
      setState((prev) => ({
        ...prev,
        selectedMaps: [map],
      }));
      return;
    }

    setState((prev) => ({
      ...prev,
      selectedMaps: [...prev.selectedMaps, map],
    }));
  };

  const onUnselectMap = (map: MapData, isMultiSelect = false) => {
    if (!isMultiSelect) {
      setState((prev) => ({
        ...prev,
        selectedMaps: [],
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      selectedMaps: prev.selectedMaps.filter((m) => m.map_key !== map.map_key),
    }));
  };

  const clearSelection = () => {
    setState((prev) => ({
      ...prev,
      selectedMaps: [],
    }));
  };

  const copyMap = async () => {
    try {
      await copyMapRequest(token, state.selectedMaps[0].map_key);
      notifications.success(t('copy_map_success').replace('{val}', state.selectedMaps[0].map_name));
      events.hideModal();
      events.clearSelection();
      mutate();
    } catch (e) {
      notifications.error(t((e as any).error.response?.data.error));
      console.error((e as any).error.response?.data.error);
    }
  };

  const deleteMap = async () => {
    try {
      await deleteMapRequest(token, state.selectedMaps[0].map_key);
      notifications.success(
        t('delete_map_success').replace('{val}', state.selectedMaps[0].map_name),
      );
      events.hideModal();
      events.clearSelection();
      mutate();
    } catch (e) {
      notifications.error(t((e as any).error.response?.data.error));
      console.error((e as any).error.response?.data.error);
    }
  };
  
  const createMapGroup = async (
    values: MapGroupRequestPayload,
    setError: UseFormSetError<MapGroupFormValues>,
  ) => {
    try {
      await createMapGroupRequest(token, { ...values }, teamId!);
      events.hideModal();
      notifications.success(t('create_map_group_success'));
      clearMapgroupSelection();
      mutate();
    } catch (e: any) {
      console.error(e.error.response.data.error);
      setError('groupName', {
        type: 'API',
        message: !e?.error?.response ? 'NO_RESPONSE' : e.error.response.data.error,
      });
    }
  };

  const deleteMapGroup = async () => {
    if (!state.selectedMapGroup) return;
    try {
      await deleteMapGroupRequest(token, teamId!, state.selectedMapGroup.group_key);
      events.hideModal();
      notifications.success(t('delete_map_group_success'));
      clearMapgroupSelection();
      mutate();
    } catch (e) {
      notifications.error(t('SOMETHING_WENT_WRONG'));
    }
  };

  const editMapGroup = async (
    values: MapGroupRequestPayload,
    setError: UseFormSetError<MapGroupFormValues>,
  ) => {
    try {
      await editMapGroupRequest(token, { ...values }, teamId!);
      events.hideModal();
      notifications.success(t('update_map_group_success'));
      clearMapgroupSelection();
      mutate();
    } catch (e: any) {
      setError('groupName', {
        type: 'API',
        message: !e?.error?.response ? 'NO_RESPONSE' : e.error.response.data.error,
      });
    }
  };

  const createShareLink = async () => {
    try {
      const response = await shareMap(token, state.selectedMaps[0].map_key);
      const { share_token } = response.data;
      const forkingLink = `${window.location.origin}${generateURL(CLONE_MAP_PAGE_PATH, {
        [MAP_SHARE_TOKEN_KEY]: share_token,
      })}`;
      return forkingLink;
    } catch (e) {
      console.error(t((e as any).error.response?.data.error));
      return '';
    }
  };

  const handleShareMap = async (map: MapData) => {
    await shareMapRequest(token, map.map_key);
  };

  const displayModal = (modalType: MAP_MANAGER_MODAL) => {
    setState((prev) => ({
      ...prev,
      modal: {
        name: modalType,
        show: true,
      },
    }));
  };

  const hideModal = () => {
    setState((prev) => ({
      ...prev,
      modal: {
        show: false,
      },
    }));
  };

  const onSelectMapGroup = (group: MapGroup) =>
    setState((prev) => ({
      ...prev,
      selectedMapGroup: group,
    }));

  const clearMapgroupSelection = () => {
    setState(({ selectedMapGroup, ...prev }) => ({
      ...prev,
    }));
  };

  const onUnselectMapGroup = () => setState(({ selectedMapGroup, ...prev }) => ({ ...prev }));

  const showMergingView = () => setState((prev) => ({ ...prev, showMergingView: true }));
  const hideMergingView = () => setState((prev) => ({ ...prev, showMergingView: false }));

  const showCopyModal = () => displayModal(MAP_MANAGER_MODAL.COPY_MAP_MODAL);
  const showExportMapModal = () => displayModal(MAP_MANAGER_MODAL.EXPORT_MAP_MODAL);
  const showDeleteMapModal = () => displayModal(MAP_MANAGER_MODAL.DELETE_MAP_MODAL);
  const displayCreateMapGroupModal = () => displayModal(MAP_MANAGER_MODAL.CREATE_MAP_GROUP_MODAL);
  const displayEditMapGroupModal = () => displayModal(MAP_MANAGER_MODAL.EDIT_MAP_GROUP_MODAL);
  const displayDeleteMapGroupModal = () => displayModal(MAP_MANAGER_MODAL.DELETE_MAP_GROUP_MODAL);

  const events = {
    onSelectMap,
    onUnselectMap,
    clearSelection,
    hideModal,
    copyMap,
    deleteMap,
    showCopyModal,
    showDeleteMapModal,
    showExportMapModal,
    createShareLink,
    showMergingView,
    hideMergingView,
    onSelectMapGroup,
    onUnselectMapGroup,
    displayCreateMapGroupModal,
    displayEditMapGroupModal,
    displayDeleteMapGroupModal,
    resetState,
  };

  const operations = {
    copyMap,
    deleteMap,
    createMapGroup,
    deleteMapGroup,
    editMapGroup,
  };

  return { handleShareMap, copyMap, events, state, operations };
};

export default useMapOperations;

import { createIcon } from '@chakra-ui/react';

const FolderIcon = createIcon({
  displayName: 'FolderIcon',
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.692"
      height="20.348"
      viewBox="0 0 25.692 20.348">
      <g id="icon_Folder" transform="translate(-36 -166.08)">
        <rect
          id="Rectangle_596"
          data-name="Rectangle 596"
          width="25.692"
          height="16.7"
          transform="translate(36 169.728)"
          fill="#707070"
        />
        <path
          id="Path_1515"
          data-name="Path 1515"
          d="M1.4,1.16H11.954l1.041,3.075H0Z"
          transform="translate(36 164.92)"
          fill="#707070"
        />
      </g>
    </svg>
  ),
});

export default FolderIcon;

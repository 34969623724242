import React from 'react';
import actions from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/actions';
import reducer from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/reducer';
import {
  MAP_MANAGER_MODAL,
  MapManagerPageState,
} from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/MapListing';
import MapListingFilter, {
  sortKeyOptions,
  sortOrderOptions,
} from '@/components/MapListingTable/MapListingFilter';
import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';
import { MapData } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';

const initialState: MapManagerPageState = {
  filter: {
    keyword: '',
    sortKey: sortKeyOptions[0].value,
    sortOrder: sortOrderOptions[0].value,
  },
  processing: false,
  selectedMaps: [],
  selectedMapGroup: '',
  modal: {
    show: false,
  },
};

const useMapState = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleSortKeySelect = (val: MAP_MANAGER_SORT_KEY): any =>
    val && dispatch(actions.changeFilter({ sortKey: val }));

  const handleSortOrderSelect = (val: SORT_ORDER) =>
    val && dispatch(actions.changeFilter({ sortOrder: val }));

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(actions.changeFilter({ keyword: event.target.value }));

  const handleMapSelect = (mapId: MapData, checked: boolean) =>
    dispatch(checked ? actions.selectMap(mapId) : actions.unselectMap(mapId));
  const clearSelectedMap = () => dispatch(actions.clearSelectedMap());

  const hideModal = () => dispatch(actions.hideModal());
  const displayCopyMapModal = () => dispatch(actions.showModal(MAP_MANAGER_MODAL.COPY_MAP_MODAL));
  const displayDeleteMapModal = () =>
    dispatch(actions.showModal(MAP_MANAGER_MODAL.DELETE_MAP_MODAL));
  const displayAccessControlModal = () =>
    dispatch(actions.showModal(MAP_MANAGER_MODAL.ACCESS_CONTROL_MODAL));
  const displayCreateMapGroupModal = () =>
    dispatch(actions.showModal(MAP_MANAGER_MODAL.CREATE_MAP_GROUP_MODAL));
  const displayExportMapGroupModal = () =>
    dispatch(actions.showModal(MAP_MANAGER_MODAL.EXPORT_MAP_MODAL));

  const handleSingleMapSelect = (map: MapData) => {
    clearSelectedMap();
    dispatch(actions.selectMap(map));
  };

  const getSelectedMaps = () => state.selectedMaps;

  const selectors = {
    getSelectedMaps,
  };

  const events = {
    handleSortKeySelect,
    handleSortOrderSelect,
    handleKeywordChange,
    handleMapSelect,
    clearSelectedMap,
    hideModal,
    displayCopyMapModal,
    displayDeleteMapModal,
    displayAccessControlModal,
    displayCreateMapGroupModal,
    displayExportMapGroupModal,
    handleSingleMapSelect,
  };
  return { state, events, dispatch, selectors };
};

export default useMapState;

import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

interface LoadingFallbackProps {
  children: React.ReactElement;
}

const LoadingFallback: React.FC<LoadingFallbackProps> = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Flex align="center" bg="white" h="100vh" justify="center" w="full">
          <Spinner />
        </Flex>
      }
    >
      {children}
    </React.Suspense>
  );
};

export default LoadingFallback;

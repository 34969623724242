import { BillingAddress } from '@/pages/DeveloperConsolePage/Setting/BillingAddressSetting';
import { OrderCheckout } from '@/pages/DeveloperConsolePage/Setting/Plan/types';
import { devApiInstance, mapApiInstance } from '@/services/request/client';
import {
  AssignMapToAppRequestPayload,
  ChangePasswordPayload,
  ChangUserInformationPayload,
  ChangUserSettingsPayload,
  CreateApplicationRequestConfig,
  DeleteApplicationRequestConfig,
  DeleteMapPayload,
  EditUserPayload,
  GiveFeedbackRequestPayload,
  ImportDeportMapPayload,
  InviteeRegistrationPayload,
  InvitingUserPayload,
  LoginPayload,
  MapGroupRequestPayload,
  MapMergingPayload,
  RegisterPayload,
  UpdateApplicationPayload,
  UpdateTeamPayload,
} from './types';

export const login = async (payload: LoginPayload) => {
  const { user_name, ...rest } = payload;
  return await devApiInstance({
    method: 'post',
    url: `/login`,
    data: {
      ...rest,
      email: user_name.toLowerCase(),
    },
  });
};

export const register = async (payload: RegisterPayload) => {
  const { user_name, ...rest } = payload;
  return await devApiInstance({
    method: 'post',
    url: `/register`,
    data: {
      ...rest,
      email: user_name.toLowerCase(),
    },
  });
};

export const getUserInfo = async (token: string) =>
  await devApiInstance({
    method: 'get',
    url: `/user`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const verifyActivateAccountToken = async (token: string) =>
  await devApiInstance({
    method: 'get',
    url: '/auth/email/verify',
    params: {
      token,
    },
  });

export const resendVerificationEmail = async (token: string) =>
  await devApiInstance({
    method: 'post',
    url: '/auth/email/resend',
    headers: { Authorization: `Bearer ${token}` },
  });

export const forgotPassword = async (email: string) =>
  await devApiInstance({
    method: 'post',
    url: '/auth/passwordrecovery/send',
    data: {
      email,
    },
  });

export const resetPassword = async (token: string, password: string) =>
  await devApiInstance({
    method: 'post',
    url: '/auth/passwordrecovery/reset',
    data: {
      token,
      password,
    },
  });

export const createOrganization = async (token: string, name: string) =>
  await devApiInstance({
    method: 'post',
    url: '/org',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      name,
    },
  });

export const updateTeam = async (token: string, payload: UpdateTeamPayload) =>
  await devApiInstance({
    method: 'put',
    url: `/org/${payload.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { name: payload.name, mfa: payload.mfa },
  });

export const createApplication = async (token: string, config: CreateApplicationRequestConfig) => {
  const url = `/org/${config.teamId}/app`;
  const data = {
    app_name: config.name,
    description: config.description,
  };
  return await devApiInstance({
    method: 'post',
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const deleteApplication = async (token: string, config: DeleteApplicationRequestConfig) => {
  const url = `/org/${config.teamId}/app`;
  const data = {
    app_key: config.appKey,
  };
  return await devApiInstance({
    method: 'delete',
    url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getUsage = async (token: string, teamId: string) =>
  await devApiInstance({
    method: 'get',
    url: `/org/${teamId}/usage`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const createMapGroup = async (
  token: string,
  payload: MapGroupRequestPayload,
  teamId: string,
) =>
  await mapApiInstance({
    method: 'post',
    url: `/group/${teamId}`,
    headers: { token },
    data: payload,
  });

export const deleteMap = async (token: string, mapKey: string) =>
  await mapApiInstance({
    method: 'delete',
    url: `/map/${mapKey}`,
    headers: { token },
  });

export const getPaymentMethod = async (token: string, teamId: number) =>
  await devApiInstance({
    method: 'get',
    url: '/billing/payment_method',
    headers: {
      token,
    },
    data: {
      org_id: teamId,
    },
  });

export const createTransaction = async (token: string, teamId: number) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/payment_method`,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

export const updatePaymentMethod = async (token: string, teamId: number, etc: any) =>
  await devApiInstance({
    method: 'patch',
    url: `/org/${teamId}/payment_method`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      org_id: teamId,
      ...etc,
    },
  });

export const getInvoiceList = async (token: string, teamId: number) =>
  await devApiInstance({
    method: 'get',
    url: `/org/${teamId}/invoice`,
    headers: {
      token,
    },
  });

export const getInvoice = async (token: string, teamId: number, id: number) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/invoice`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      id,
      type: 'invoice',
    },
  });

export const getReceipt = async (token: string, teamId: number, id: number) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/receipt`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: {
      id,
      type: 'receipt',
    },
  });

export const changePlan = async (token: string, teamId: number, planID: number) =>
  await devApiInstance({
    method: 'put',
    url: `/org/${teamId}/pricing_plan`,
    headers: {
      token,
    },
    data: {
      new_pricing_plan_id: planID,
    },
  });

export const copyMap = async (token: string, mapKey: string) =>
  await mapApiInstance({
    method: 'post',
    url: `map/copy/${mapKey}`,
    headers: {
      token,
    },
  });

export const updateApp = async (
  token: string,
  teamId: string,
  applicationData: UpdateApplicationPayload,
) => {
  return await devApiInstance({
    method: 'put',
    url: `org/${teamId}/app`,
    headers: { Authorization: `Bearer ${token}` },
    data: applicationData,
  });
};

export const getAnalyticsData = async (token: string, teamId: string, payload: any) =>
  await devApiInstance({
    method: 'post',
    url: `org/${teamId}/analytics`,
    headers: {
      token,
    },
    data: payload,
  });

export const acceptTerms = async (token: string) =>
  await devApiInstance({
    method: 'put',
    url: 'terms-of-use',
    headers: { Authorization: `Bearer ${token}` },
  });

export const inviteUser = async (payload: InvitingUserPayload, teamId: string, token: string) =>
  await devApiInstance({
    method: 'post',
    data: payload,
    url: `/org/${teamId}/member`,
    headers: { Authorization: `Bearer ${token}` },
  });

export const editUser = async (payload: EditUserPayload, teamId: string, token: string) =>
  await devApiInstance({
    method: 'put',
    data: payload,
    url: `/org/${teamId}/member`,
    headers: { Authorization: `Bearer ${token}` },
  });

export const activateMember = async (token: string) =>
  await devApiInstance({
    method: 'patch',
    url: `/member?token=${token}`,
  });

export const inviteeRegister = async (payload: InviteeRegistrationPayload) =>
  await devApiInstance({
    method: 'patch',
    url: `/register`,
    data: payload,
  });

export const editMapGroup = async (
  token: string,
  payload: MapGroupRequestPayload,
  teamId: string,
) => {
  const { groupId, ...rest } = payload;
  return await mapApiInstance({
    method: 'put',
    url: `/group/${teamId}/${groupId}`,
    headers: { token },
    data: rest,
  });
};

export const deleteMapGroup = async (token: string, teamId: string, groupId: string) =>
  await mapApiInstance({
    method: 'delete',
    url: `/group/${teamId}/${groupId}`,
    headers: { token },
  });

export const assignMapToApp = async (
  token: string,
  teamId: string,
  payload: AssignMapToAppRequestPayload,
) => {
  const { map_key, ...rest } = payload;
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/map/${map_key}/apps`,
    data: rest,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const importMapToApp = async (
  token: string,
  teamId: string,
  appKey: string,
  payload: ImportDeportMapPayload,
) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/app/${appKey}/maps`,
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

export const changeUserInfomation = async (payload: ChangUserInformationPayload, token: string) =>
  await devApiInstance({
    method: 'put',
    url: '/user',
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

export const changeUserSettings = async (payload: ChangUserSettingsPayload, token: string) =>
  await devApiInstance({
    method: 'put',
    url: '/user/settings',
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

export const changePassword = async (payload: ChangePasswordPayload, token: string) =>
  await devApiInstance({
    method: 'put',
    url: '/password',
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

export const deleteMember = async (teamId: string, memberId: number, token: string) =>
  await devApiInstance({
    method: 'delete',
    headers: { Authorization: `Bearer ${token}` },
    url: `/org/${teamId}/member/${memberId}`,
  });

export const leaveOrg = async (teamId: string, token: string) =>
  await devApiInstance({
    method: 'delete',
    headers: { Authorization: `Bearer ${token}` },
    url: `/org/${teamId}/leave`,
  });

export const getSDKDownloadLink = async (token: string) =>
  await devApiInstance({
    method: 'get',
    url: '/download/sdk',
    headers: { Authorization: `Bearer ${token}` },
  });

export const getScannerAppDownloadLink = async (token: string) =>
  await devApiInstance({
    method: 'get',
    url: '/download/scanner',
    headers: { Authorization: `Bearer ${token}` },
  });

export const giveFeedback = async (token: string, payload: GiveFeedbackRequestPayload) =>
  await devApiInstance({
    method: 'post',
    url: '/feedback',
    data: payload,
    headers: { Authorization: `Bearer ${token}` },
  });

export const createApiKey = async (token: string, teamId: number) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/api-key`,
    headers: { Authorization: `Bearer ${token}` },
  });

export const deleteApiKey = async (token: string, teamId: number, key: string) =>
  await devApiInstance({
    method: 'delete',
    url: `/org/${teamId}/api-key/${key}`,
    headers: { Authorization: `Bearer ${token}` },
  });

export const getAppIconUploadURL = async (token: string, appKey: string) =>
  await devApiInstance({
    method: 'put',
    url: '/platform-storage/app-icon',
    headers: { Authorization: `Bearer ${token}` },
    data: {
      app_key: appKey,
      file_names: ['appIcon'],
    },
  });

export const deleteAppIcon = async (token: string, appKey: string) =>
  await devApiInstance({
    method: 'post',
    url: `/platform-storage/app-icon/delete/${appKey}`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      file_names: ['appIcon'],
    },
  });

export const shareMap = async (token: string, mapKey: string) =>
  await mapApiInstance({
    method: 'post',
    url: `/map/share/${mapKey}`,
    headers: { token },
  });

export const getUserOrganizationsAndMapGroups = async (token: string) =>
  await mapApiInstance({
    method: 'GET',
    url: `/all-groups`,
    headers: { token },
  });

export const forkMap = async (
  token: string,
  mapToken: string,
  targetOrg: number,
  targetGroup: string,
) =>
  await mapApiInstance({
    method: 'post',
    url: '/map/fork',
    headers: { token },
    data: {
      share_token: mapToken,
      target_org_id: targetOrg,
      target_map_group_key: targetGroup,
    },
  });

export const verifyMFA = async (code: string, token: string) =>
  await devApiInstance({
    method: 'post',
    url: `/auth/mfa/verify`,
    headers: { Authorization: `Bearer ${token}` },
    data: { code },
  });

export const startSeatExtensionCheckout = async (teamId: number, amount: number, token: string) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/seats/checkout`,
    headers: { Authorization: `Bearer ${token}` },
    data: { count: amount },
  });

export const getSeatPriceToday = async (teamId: number, amount: number, token: string) =>
  await devApiInstance({
    method: 'get',
    url: `/org/${teamId}/seats/price/${amount}`,
    headers: { Authorization: `Bearer ${token}` },
    // data: { count: amount },
  });

export const cancelSeatExtensionCheckout = async (teamId: number, orderId: number, token: string) =>
  await devApiInstance({
    method: 'delete',
    url: `/org/${teamId}/seats/checkout/${orderId}`,
    headers: { Authorization: `Bearer ${token}` },
    data: {},
  });

export const finishSeatExtensionCheckout = async (
  teamId: number,
  orderId: number,
  token: string,
  orderToken: string,
) =>
  await devApiInstance({
    method: 'put',
    url: `/org/${teamId}/seat/checkout/${orderId}`,
    headers: { Authorization: `Bearer ${token}` },
    data: {
      authorization_token: orderToken,
    },
  });

export const startSubscriptionCheckout = async (teamId: number, token: string, annual: boolean) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/subscription/premium/checkout`,
    headers: { Authorization: `Bearer ${token}` },
    data: { promo: annual },
  });

export const finishSubscriptionCheckout = async (
  teamId: number,
  orderId: number,
  token: string,
  orderToken: string,
) =>
  await devApiInstance({
    method: 'put',
    url: `/org/${teamId}/subscription/premium/checkout/${orderId}`,
    headers: { Authorization: `Bearer ${token}` },
    data: { authorization_token: orderToken },
  });

export const startPremiumDowngrade = async (teamId: number, token: string, newPlain: number) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/subscription/premium/downgrade_request`,
    headers: { Authorization: `Bearer ${token}` },
    data: { new_plan_id: newPlain },
  });

export const requestSwitchToMonthlyPlan = async (teamId: number, token: string) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/subscription/premium/to_monthly_request`,
    headers: { Authorization: `Bearer ${token}` },
    data: {},
  });

export const startSeatsDowngrade = async (
  teamId: number,
  token: string,
  amount: number,
  membersToRemove: number[],
  runAtEndOfPlan?: boolean,
) =>
  await devApiInstance({
    method: 'post',
    url: `/org/${teamId}/seats/reduction`,
    headers: { Authorization: `Bearer ${token}` },
    data: { count: amount, members: membersToRemove, run_at_end: runAtEndOfPlan },
  });

export const setBillingAddress = async (payload: BillingAddress, token: string, teamId: string) =>
  await devApiInstance({
    method: 'put',
    url: `org/${teamId}/settings/billing-address`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: payload,
  });

export const changeActiveVersion = async (
  token: string,
  payload: { mapKey: string; version: number },
) =>
  await mapApiInstance({
    method: 'put',
    url: `/map-version/${payload.mapKey}`,
    headers: { token },
    data: { version: payload.version },
  });

export const deleteMapVersion = async (
  token: string,
  payload: { mapKey: string; version: number },
) =>
  await mapApiInstance({
    method: 'delete',
    url: `/map-version/${payload.mapKey}/${payload.version}`,
    headers: { token },
  });

export const updateMapName = async (token: string, payload: { mapKey: string; mapName: string }) =>
  await mapApiInstance({
    method: 'PUT',
    url: `/map/name/${payload.mapKey}`,
    headers: { token },
    data: { map_name: payload.mapName },
  });

export const updateVersionNote = async (
  token: string,
  payload: { mapKey: string; note: string; version: number },
) =>
  await mapApiInstance({
    method: 'put',
    url: `/map-version/${payload.mapKey}/${payload.version}`,
    headers: { token },
    data: { alias: payload.note },
  });

export const cancelSchedule = async (id: number, token: string, teamId: string) =>
  await devApiInstance({
    method: 'delete',
    url: `org/${teamId}/schedules/cancel`,
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: { id: id },
  });

export const checkout = async (
  teamId: number,
  orderId: number,
  token: string,
  orderToken: string,
) =>
  await devApiInstance({
    method: 'put',
    url: `/org/${teamId}/orders/checkout/${orderId}`,
    headers: { Authorization: `Bearer ${token}` },
    data: { authorization_token: orderToken },
  });

export const cancelStartedCheckout = async (teamId: number, orderId: number, token: string) =>
  await devApiInstance({
    method: 'delete',
    url: `/org/${teamId}/orders/checkout/${orderId}`,
    headers: { Authorization: `Bearer ${token}` },
    data: {},
  });

export const getOrderReceipt = async (
  teamId: number,
  orderId: number,
  token: string,
  lang: string,
) =>
  await devApiInstance({
    method: 'GET',
    url: `/org/${teamId}/receipt/${orderId}/${lang}`,
    headers: { Authorization: `Bearer ${token}` },
  });

export const getMeshingData = async (mapId: string, token: string, version: number) =>
  await mapApiInstance({
    method: 'GET',
    url: `/map-data/${mapId}/${version}/meshing?convert=true`,
    headers: { Authorization: `Bearer ${token}` },
  });

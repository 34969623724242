import i18n from '@/utils/i18n';
import { Stack, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import React from 'react';
import Link from '../Link';

const NoMapData: React.FC = (props) => {
  return (
    <Stack>
      <Text align="center">
        {i18n.language === 'en' ? (
          <>
            {t('please_add_new_map_with_scanner')}{' '}
            <Link
              target="_blank"
              to="https://docs.arcloud.pretiaar.com/3d-scanner-app/create-new-map">
              {t('this')}
            </Link>
            .
          </>
        ) : (
          <>
            <Link
              target="_blank"
              to="https://docs.arcloud.pretiaar.com/ja/3d-scanner-app/create-new-map/">
              {t('this')}
            </Link>
            {t('please_add_new_map_with_scanner')}
          </>
        )}
      </Text>
    </Stack>
  );
};

export default NoMapData;

import addHours from 'date-fns/addHours';
import { atom } from 'recoil';
import localStorageEffect from '@/store/atoms/effects/localStorage';
import { UserState } from './types';

const localStorageEffectOptions = {
  expiry: addHours(new Date(), 6),
};

export const userState = atom<UserState>({
  key: 'user',
  default: {
    token: '',
    isAuthenticated: false,
    referralCode: '',
  },
  effects: [localStorageEffect(localStorageEffectOptions)],
});

interface ApiErrorResponse {
  data: {
    error: string;
  };
}

export interface RequestError {
  response: ApiErrorResponse;
}

export enum SERVER_SIDE_ERROR {
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  NOT_ENOUGH_PRIVILEGE = 'NOT_ENOUGH_PRIVILEGE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVITATION_PENDING = 'INVITATION_PENDING',
}

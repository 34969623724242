import { atom } from 'recoil';
import localStorageEffect from '@/store/atoms/effects/localStorage';
import { ExperimentalState } from './types';

const experimentalState = atom<ExperimentalState>({
  key: 'experiments',
  default: {
    internal: false,
    beta: false,
  },
  effects: [localStorageEffect()],
});

export default experimentalState;

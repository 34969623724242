import useSWR from 'swr';
import { userState } from '@/store/atoms/users';
import { useRecoilValue } from 'recoil';
import { GetOrganizationResponse, Organization } from '@/pages/OrganizationPage/types';
import { swrFetcherWithToken } from '@/services/request/client';

const useFetchUserTeams = () => {
  // required data
  const { token } = useRecoilValue(userState);

  // url builder
  const baseURL = import.meta.env.VITE_DEV_API_HOSTNAME;
  const path = ['org'];
  const endpoint = `${baseURL}/${path.join('/')}`;

  // key condition
  const condition = token ? ([endpoint, token] as [string, string]) : null;

  // request setup
  return useSWR<Organization[], any, [string, string] | null>(condition, ([url, token]) =>
    swrFetcherWithToken<GetOrganizationResponse>(url, token).then((res) => res.orgs),
  );
};

export default useFetchUserTeams;

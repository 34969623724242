import * as yup from 'yup';
import locale from './locales';

yup.setLocale({ ...locale });

export const MAXIMUM_STRING_LENGTH_ALLOWED = 25;
export const fullNameOptionalRule = yup.string().max(72).trim();
export const fullnameRule = fullNameOptionalRule.required();
export const emailRule = yup.string().required().email();
export const passwordRule = yup.string().required().min(8).max(72);
export const registerPagePasswordRule = passwordRule
  .matches(/^(?=.*[a-z])/, 'password_must_contain_lowercase')
  .matches(/^(?=.*[A-Z])/, 'password_must_contain_uppercase')
  .matches(/^(?=.*[0-9])/, 'password_must_contain_number');

export const passwordConfirmationRule = yup
  .string()
  .required()
  .oneOf([yup.ref('password')], 'password_must_be_match');

export const organizationNameRule = yup
  .string()
  .required()
  .trim()
  .max(MAXIMUM_STRING_LENGTH_ALLOWED);
export const applicationNameRule = yup
  .string()
  .required()
  .trim()
  .max(MAXIMUM_STRING_LENGTH_ALLOWED);
export const applicationDescriptionRule = yup.string().trim().max(100);

export const mapVersionDescriptionRule = applicationDescriptionRule;
export const mapGroupRule = yup.string().max(MAXIMUM_STRING_LENGTH_ALLOWED).required().trim();
export const mapNameRule = yup.string().max(MAXIMUM_STRING_LENGTH_ALLOWED).required().trim();
export const displayNameRule = yup.string().min(3).required().trim().max(30);
export const referralRule = yup.string();

export const billingAddressName = yup.string().max(50);
export const billingAddressLine1 = yup.string().max(50);
export const billingAddressLine2 = yup.string().max(50);
export const billingAddressCity = yup.string().max(20);
export const billingAddressState = yup.string().max(20);
export const billingAddressPhoneNumber = yup.string().max(20);
export const billingAddressCountry = yup.string().max(56);
export const billingAddressZipcode = yup.string().max(20);

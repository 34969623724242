import { atom } from 'recoil';
import localStorageEffect from '../effects/localStorage';
import { ThreeDimensionConfig, UIState } from './types';

const userLang = navigator.language || (navigator as any).userLanguage;

export const threeDimensionDefaultConfig: ThreeDimensionConfig = {
  meshing: {
    color: '#0f0',
    emissive: '#00f',
    show: true,
    opacity: 0.5,
  },
  background: {
    show: true,
    color: '#f5efe6',
  },
};

const uiState = atom<UIState>({
  key: 'ui',
  default: {
    show3DViewer: false,
    language: userLang === 'ja' ? 'jp' : 'en',
    isFinishedFirstTimeWizard: false,
    threeDimensionConfig: threeDimensionDefaultConfig,
  },
  effects: [localStorageEffect()],
});

export default uiState;

import qs, { ParsedQuery } from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Cookies from 'js-cookie';
import experimentalState from '@/store/atoms/experiments';
import currentUserPreferences from '@/store/atoms/selectors/currentUserPreferences';
import uiState from '@/store/atoms/ui';
import { UIState } from '@/store/atoms/ui/types';

const SUPPORT_LANG = ['jp', 'en'];
const AVAILABLE_COMMAND = ['listexp'];

const WebCommandRoot: React.FC = () => {
  const location = useLocation();
  const [experiments, setExperiments] = useRecoilState(experimentalState);
  const [uiStateValue, setUIStateValue] = useRecoilState(uiState);
  const { i18n } = useTranslation();

  React.useEffect(() => {
    const lang = uiStateValue.language;
    if (!lang) return;
    i18n.changeLanguage(lang);
  }, []);

  React.useEffect(() => {
    setUIStateValue((prev) => ({ ...prev, language: i18n.language as UIState['language'] }));
  }, [i18n.language]);

  const clearURLParams = () => {
    return <Navigate to={location.pathname} />;
  };

  const switchLanguage = (querystring: ParsedQuery<string>) => {
    const { lang } = querystring;
    if (!lang || Array.isArray(lang) || !SUPPORT_LANG.includes(lang)) return;
    i18n.changeLanguage(lang);
    setUIStateValue((prev) => ({ ...prev, language: lang as UIState['language'] }));
  };

  const switchExperimentFeature = (querystring: ParsedQuery<string>) => {
    let { explist, expunlist } = querystring;
    if (!explist && !expunlist) return;
    if (typeof explist === 'string') explist = [explist];
    if (typeof expunlist === 'string') expunlist = [expunlist];

    const updatedExp = Object.keys(experiments).reduce(
      (acc, cur) => {
        if (explist?.includes(cur)) return { ...acc, [cur]: true };
        if (expunlist?.includes(cur)) return { ...acc, [cur]: false };
        return acc;
      },
      { ...experiments },
    );
    setExperiments(updatedExp);
  };

  const commandReader = (querystring: ParsedQuery<string>) => {
    const { cmd } = querystring;
    if (!cmd || Array.isArray(cmd) || !AVAILABLE_COMMAND.includes(cmd)) return;
    if (cmd === 'listexp') console.log(experiments);
  };

  React.useEffect(() => {
    const querystring = qs.parse(location.search, {
      arrayFormat: 'separator',
      arrayFormatSeparator: ',',
    });
    commandReader(querystring);
    switchExperimentFeature(querystring);
    switchLanguage(querystring);
    if (location.search) {
      clearURLParams();
    }
  }, [location.search]);

  return <></>;
};

export default WebCommandRoot;

import React from 'react';
import { GroupWithMaps } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  useClipboard,
  Text,
  Stack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import FolderIcon from '../Icon/FolderIcon';
import MoreIcon from '../Icon/MoreIcon';
import useMapOperations from '@/hooks/use-map-operations';
interface MapListingGroupProps {
  group: GroupWithMaps;
  children: React.ReactNode;
  withMenus?: boolean;
}

const MapListingGroup: React.FC<MapListingGroupProps> = ({ group, children, withMenus }) => {
  const { events } = useMapOperations();
  const { onCopy } = useClipboard(group.group_key);
  const [flag, setFlag] = React.useState(false);
  const { t } = useTranslation();

  const toggle = () => setFlag(!flag);

  const menus = [];
  menus.push({
    label: t('copy_group_key'),
    action: onCopy,
  });

  menus.push({
    label: t('edit_map_group'),
    action: () => {
      events.onSelectMapGroup(group);
      events.displayEditMapGroupModal();
    },
  });

  if (!group.maps.length) {
    menus.push({
      label: t('delete_map_group'),
      action: () => {
        events.onSelectMapGroup(group);
        events.displayDeleteMapGroupModal();
      },
    });
  }
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={4}>
        <Box role="button" onClick={toggle}>
          {flag ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Box>
        <Text fontSize="lg">
          <FolderIcon mr="4" />
        </Text>
        <Text fontSize="lg" color={!group.maps.length ? 'gray.400' : 'inherit'}>
          {group.group_name}
        </Text>
        {withMenus && (
          <Menu>
            <MenuButton as={IconButton} variant="link" icon={<MoreIcon />} size="md" />
            <MenuList>
              {menus.map((menu) => (
                <MenuItem onClick={menu.action} key={`${group.group_key}-${menu.label}`}>
                  {t(menu.label)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Stack>
      <Stack pl="6">
        <Collapse in={flag} animateOpacity>
          {children}
        </Collapse>
      </Stack>
    </>
  );
};

export default MapListingGroup;

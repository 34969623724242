import React from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface SearchboxProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
}

const Searchbox: React.FC<SearchboxProps> = ({ defaultValue, onChange }) => {
  const { t } = useTranslation();
  return (
    <InputGroup size="sm">
      <Input
        onChange={onChange}
        type="text"
        placeholder={t('keyword')}
        defaultValue={defaultValue}
      />
      ;
      <InputRightElement children={<SearchIcon color="blue.500" />} />
    </InputGroup>
  );
};

export default Searchbox;

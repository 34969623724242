import { ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import { TourProvider } from '@reactour/tour';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import WebCommandRoot from './components/WebCommandRoot';
import AppRoutes from './pages/AppRoutes';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './utils/i18n';
import 'tippy.js/dist/tippy.css';
import 'react-day-picker/dist/style.css';
import './styles/bg-image.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import SWRConfig from './components/SWRConfig';
import theme from './theme';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY!);

const AppRoot: React.FC = () => {
  return (
    <React.StrictMode>
      <RecoilRoot>
        <BrowserRouter basename={import.meta.env.REACT_APP_BASE_URL}>
          <SWRConfig>
            <ChakraProvider
              theme={theme}
              toastOptions={{ defaultOptions: { position: 'top-right' } }}>
              <TourProvider steps={[]}>
                <div className="font-body">
                  <WebCommandRoot />
                  <Elements stripe={stripePromise}>
                    <React.Suspense
                      fallback={
                        <Flex w="100vw" h="100vh" align="center" justify="center">
                          <Spinner />
                        </Flex>
                      }>
                      <AppRoutes />
                    </React.Suspense>
                  </Elements>
                </div>
              </TourProvider>
            </ChakraProvider>
          </SWRConfig>
        </BrowserRouter>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default AppRoot;

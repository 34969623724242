import { createIcon } from '@chakra-ui/react';

const MapIcon = createIcon({
  displayName: 'MapIcon',
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.248"
      height="19.877"
      viewBox="0 0 24.248 19.877">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_622"
            data-name="Rectangle 622"
            width="24.248"
            height="19.877"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="icon_Map_04" transform="translate(0 0)">
        <g
          id="Group_623"
          data-name="Group 623"
          transform="translate(0 0)"
          clipPath="url(#clip-path)">
          <path
            id="Path_1545"
            data-name="Path 1545"
            d="M23.707,16.508l-7.778,2.615L8.236,16.508.5,19.123V3.311L8.236.7l7.693,2.615L23.707.7Z"
            transform="translate(0.02 0.028)"
            fill="none"
            stroke="#707070"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <line
            id="Line_43"
            data-name="Line 43"
            y2="15.812"
            transform="translate(8.256 0.724)"
            fill="none"
            stroke="#707070"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <line
            id="Line_44"
            data-name="Line 44"
            y2="15.812"
            transform="translate(15.95 3.339)"
            fill="none"
            stroke="#707070"
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
      </g>
    </svg>
  ),
});

export default MapIcon;

import { Button, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@/components/ErrorMessage';
import Input from '@/components/Input';
import Modal from '@/components/Modal';
import { TFunction } from 'i18next';
import React from 'react';
import { useForm, UseFormReturn, UseFormSetError } from 'react-hook-form';
import { MapGroupRequestPayload } from '@/services/request/types';
import { mapGroupRule } from '@/validations';
import * as yup from 'yup';
import { MapGroup as MapGroupType } from '../../types';

interface MapGroupProps {
  data?: MapGroupType;
  handleClose: () => void;
  t: TFunction;
  error?: string;
  handleConfirm: (
    values: MapGroupRequestPayload,
    setError: UseFormSetError<MapGroupFormValues>,
  ) => void;
  show: boolean;
}

export interface MapGroupFormValues {
  groupName: string;
}

const MapGroup: React.FC<MapGroupProps> = ({
  t = (v: string) => v,
  handleClose,
  handleConfirm,
  data,
  show,
}) => {
  const schema = yup.object().shape({
    groupName: mapGroupRule.label(t('map_group_name')),
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<MapGroupFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      groupName: data?.group_name,
    },
  });

  React.useEffect(() => {
    reset({ groupName: data?.group_name ?? '' });
  }, [data?.group_name]);

  const onSubmit = async (values: MapGroupFormValues) => {
    handleConfirm(
      { group_name: values.groupName, groupId: data?.group_key, description: '' },
      setError,
    );
  };
  return (
    <Modal
      footer={
        <HStack>
          {handleClose && (
            <Button
              colorScheme="gray"
              size="sm"
              type="button"
              variant="outline"
              w="100px"
              onClick={handleClose}>
              {t('cancel')}
            </Button>
          )}
          <Button
            colorScheme="blue"
            form="map-group-form"
            size="sm"
            type="submit"
            variant="solid"
            w="100px">
            {data ? t('save') : t('create')}
          </Button>
        </HStack>
      }
      show={show}
      title={data ? t('edit_map_group') : t('create_map_group')}
      onClose={handleClose}>
      <form id="map-group-form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          register={register('groupName')}
          label={t('map_group_name')}
          type="text"
          error={errors.groupName?.message}
        />
      </form>
    </Modal>
  );
};

export default MapGroup;

import React from 'react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import clsx from 'clsx';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

export interface ModalProps {
  title?: React.ReactNode;
  show: boolean;
  onClose: () => void;
  footer?: React.ReactNode;
  allowClose?: boolean;
  closeOnOverlayClick?: boolean;
  size?: ChakraModalProps['size'];
  hideCloseButton?: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  allowClose = true,
  closeOnOverlayClick = true,
  size = 'md',
  hideCloseButton = false,
  ...props
}) => {
  const noop = () => {
    return;
  };

  return (
    <ChakraModal
      isCentered
      blockScrollOnMount={true}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={props.show}
      size={size}
      onClose={allowClose ? props.onClose : noop}>
      <ModalOverlay sx={{ backdropFilter: 'blur(10px) ', background: 'blackAlpha.700' }} />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        {allowClose && !hideCloseButton && <ModalCloseButton />}

        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>{props.footer}</ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;

import React from 'react';
import clsx from 'clsx';
import { ContentCopy } from '@styled-icons/material/ContentCopy';
import { baseInputClass } from '@/components/Input';
import Label from '@/components/Label';
import { IconButton, Input, InputGroup, InputRightElement, useClipboard } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import useNotification from '@/hooks/use-notification';

interface CopyFieldProps {
  content: string;
  label?: string;
}

const CopyField: React.FC<CopyFieldProps> = ({ label, content }) => {
  const { onCopy } = useClipboard(content);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const notification = useNotification();

  const copyToClipboard = () => {
    onCopy();
    notification.info('copied');
  };

  return (
    <InputGroup size="md">
      <Input ref={inputRef} type="text" value={content} readOnly={true} />
      <InputRightElement>
        <IconButton aria-label="Copy" icon={<CopyIcon />} onClick={copyToClipboard} size="sm" />
      </InputRightElement>
    </InputGroup>
  );
};

export default CopyField;

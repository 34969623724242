export const LANDING_PAGE_PATH = '/home';

export const CONSOLE_ROOT_PATH = '/console';

// common routes
export const INVITATION_TOKEN_KEY = 'inviteToken';
export const RESET_PASSWORD_TOKEN_KEY = 'resetPasswordToken';
export const LOGOUT_PAGE_PATH = `${CONSOLE_ROOT_PATH}/logout`;
export const LOGIN_PAGE_PATH = `${CONSOLE_ROOT_PATH}/login`;
export const LOGIN_PAGE_PATH_WITH_TOKEN = `${LOGIN_PAGE_PATH}/:${INVITATION_TOKEN_KEY}`;
export const RESET_PASSWORD_PAGE_PATH = `${CONSOLE_ROOT_PATH}/reset-password/:${RESET_PASSWORD_TOKEN_KEY}`;
export const FORGOT_PASSWORD_PAGE_PATH = `${CONSOLE_ROOT_PATH}/forgot-password`;
export const TERM_PAGE_PATH = `${CONSOLE_ROOT_PATH}/terms`;
export const TERM_PAGE_PATH_WITH_INVITE_TOKEN = `${TERM_PAGE_PATH}/:${INVITATION_TOKEN_KEY}`;

// register-related pages
export const VERIFICATION_TOKEN_KEY = 'verificationToken';
export const REGISTER_PAGE_PATH = `${CONSOLE_ROOT_PATH}/register`;
export const REGISTER_SUCCESS_PAGE_PATH = `${REGISTER_PAGE_PATH}/success`;
export const VERIFICATION_PAGE_PATH = `${REGISTER_PAGE_PATH}/:${VERIFICATION_TOKEN_KEY}`;

// invite-related pages
const INVITE_ROOT_PATH = `${CONSOLE_ROOT_PATH}/invite`;
export const INVITE_PAGE_PATH = `${INVITE_ROOT_PATH}/:${INVITATION_TOKEN_KEY}`;
export const INVITE_ACCOUNT_CREATION_PAGE_PATH = `${INVITE_ROOT_PATH}/new-account/:${INVITATION_TOKEN_KEY}`;

// team
export const TEAM_ROOT_PATH = `${CONSOLE_ROOT_PATH}/team`;
export const CREATE_TEAM_PAGE_PATH = `${TEAM_ROOT_PATH}/create`;
export const TEAM_ID_KEY = 'teamId';
export const TEAM_PAGE_PATH = `${TEAM_ROOT_PATH}/:${TEAM_ID_KEY}`;
export const PENDING_TEAM_PAGE_PATH = `${TEAM_PAGE_PATH}/pending`;

// App pages
export const APP_ID_KEY = 'appKey';
export const APP_PAGE_PATH = `${TEAM_PAGE_PATH}/app`;
export const APP_DETAIL_PAGE_PATH = `${APP_PAGE_PATH}/:${APP_ID_KEY}`;
export const APP_EDIT_PAGE_PATH = `${APP_DETAIL_PAGE_PATH}/edit`;

// Map pages
export const MAP_ID_KEY = 'mapId';
export const MAP_MANAGER_PAGE_PATH = `${TEAM_PAGE_PATH}/map`;
export const MAP_DETAILED_PAGE_PATH = `${MAP_MANAGER_PAGE_PATH}/data/:${MAP_ID_KEY}`;

export const API_KEY_MANAGEMENT_PAGE_PATH = `${TEAM_PAGE_PATH}/keys`;
export const SUBSCRIPTIONS_PAGE_PATH = `${TEAM_PAGE_PATH}/subscriptions`;
export const METRICS_PAGE_PATH = `${TEAM_PAGE_PATH}/metrics`;
export const SCHEDULES_PAGE_PATH = `${TEAM_PAGE_PATH}/plan/requests`;
export const ORDERS_PAGE_PATH = `${TEAM_PAGE_PATH}/orders`;

// settings page
export const USER_ID_KEY = 'memberId';
export const SETTING_PAGE_PATH = `${TEAM_PAGE_PATH}/settings`;
export const LICENSE_PAGE_PATH = `${SETTING_PAGE_PATH}/licenses`;
export const PAYMENT_PAGE_PATH = `${SETTING_PAGE_PATH}/payment`;
export const PAYMENT_HISTORY_PAGE_PATH = `${PAYMENT_PAGE_PATH}/history`;
export const PLAN_SETTING_PAGE_PATH = `${SETTING_PAGE_PATH}/plan`;
export const USER_SETTTING_PAGE_PATH = `${SETTING_PAGE_PATH}/users`;
export const USER_INVITE_PAGE_PATH = `${USER_SETTTING_PAGE_PATH}/invite`;
export const USER_EDIT_PAGE_PATH = `${USER_SETTTING_PAGE_PATH}/:${USER_ID_KEY}`;

//pricing pages
export const CHECKOUT_TEAM_PAGE_PATH = `${TEAM_PAGE_PATH}/checkout`;

// Standalone pages
const INDEPENDENT_PAGE_ROOT_PATH = `${CONSOLE_ROOT_PATH}/-`;
export const MAP_SHARE_TOKEN_KEY = 'shareToken';
export const DOWNLOAD_PAGE_PATH = `${INDEPENDENT_PAGE_ROOT_PATH}/download`;
export const FEEDBACK_PAGE_PATH = `${INDEPENDENT_PAGE_ROOT_PATH}/feedback`;
export const CLONE_MAP_PAGE_PATH = `${INDEPENDENT_PAGE_ROOT_PATH}/clone/map/:${MAP_SHARE_TOKEN_KEY}`;
export const OSS_LICENSE_PAGE_PATH = `${INDEPENDENT_PAGE_ROOT_PATH}/licenses`;

// Personal pages
export const DEVELOPER_SETTINGS_MAIN_PAGE_PATH = `${INDEPENDENT_PAGE_ROOT_PATH}/account`;
export const DEVELOPER_SETTINGS_DETAIL_PAGE_PATH = `${DEVELOPER_SETTINGS_MAIN_PAGE_PATH}/info`;
export const DEVELOPER_SETTINGS_SECURITY_MFA_PAGE_PATH = `${DEVELOPER_SETTINGS_MAIN_PAGE_PATH}/multi-factor-authentication`;
export const DEVELOPER_SETTINGS_SECURITY_CHANGE_PASSWORD_PATH = `${DEVELOPER_SETTINGS_MAIN_PAGE_PATH}/password`;

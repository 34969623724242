import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '@/assets/locales/en.json';
import jaTranslation from '@/assets/locales/ja.json';

const resources = {
  en: {
    translation: { ...enTranslation },
  },
  jp: {
    translation: { ...jaTranslation },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
  });

export default i18n;

import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';
import React from 'react';

const useMapFilter = () => {
  const [filter, setFilter] = React.useState({
    keyword: '',
    sortKey: MAP_MANAGER_SORT_KEY.NAME,
    sortOrder: SORT_ORDER.ASC,
  });

  const setSortKey = (val: MAP_MANAGER_SORT_KEY) => {
    setFilter((prev) => ({ ...prev, sortKey: val }));
  };

  const setSortOrder = (val: SORT_ORDER) => {
    setFilter((prev) => ({ ...prev, sortOrder: val }));
  };

  const setKeyword = (val: string) => {
    setFilter((prev) => ({ ...prev, keyword: val }));
  };

  const setters = {
    setSortKey,
    setSortOrder,
    setKeyword,
  };

  return { setters, filter };
};

export default useMapFilter;

import React from 'react';
import { DeleteOutline } from '@styled-icons/material-outlined/DeleteOutline';
import { useParams, useSearchParams } from 'react-router-dom';
import Container from '@/templates/DeveloperConsole/PageContainer';
import { GroupWithMaps, MapData, MapGroup, MapMergingResponse } from '../types';
import ACLModal from './ACLModal';
import { useTranslation } from 'react-i18next';
import { MergeType } from '@styled-icons/material-outlined/MergeType';
import {
  deleteMap as deleteMapRequest,
  deleteMapGroup as deleteMapGroupRequest,
  copyMap as copyMapRequest,
  createMapGroup as createMapGroupRequest,
  editMapGroup as editMapGroupRequest,
  assignMapToApp as assignMapToAppRequest,
  forkMap as forkMapRequest,
} from '@/services/request';
import { useRecoilValue } from 'recoil';
import { MapGroupRequestPayload, MapMergingPayload } from '@/services/request/types';
import { RequestStatus } from '@/types/request';
import clsx from 'clsx';
import { SelectOption } from '@/components/Select/types';
import useExperimentalValue from '@/hooks/use-experimentalValue';
import { VerticalAlignCenter } from '@styled-icons/material/VerticalAlignCenter';

import actions from '../actions';
import Modal from '@/components/Modal';
import DeleteMap from './Modal/DeleteMap';
import { default as MapGroupModal } from './Modal/MapGroup';
import MergeMap, { MergingResult } from './Modal/MergeMap';
import CopyMap from './Modal/CopyMap';
import AlignMap from './Modal/AlignMap';
import { UseFormReturn } from 'react-hook-form';
import DeleteMapGroup from './Modal/DeleteMapGroup';
import MapListingTable from '@/components/MapListingTable';
import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';
import MapListingFilter, {
  sortKeyOptions,
  sortOrderOptions,
} from '@/components/MapListingTable/MapListingFilter';
import { Link } from '@/components/Link';
import { userState } from '@/store/atoms/users';
import ExportMap from './Modal/ExportMap';
import ForkingMap from './Modal/ForkingMap';
import { Box, Button, ButtonGroup, Icon, Stack } from '@chakra-ui/react';
import useFetchUserRoles from '@/hooks/fetcher/use-fetch-user-roles';
import { CopyIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import useMapState from '@/hooks/map/use-map-state';
import useMapOperations from '@/hooks/use-map-operations';
import useNotification from '@/hooks/use-notification';

interface Collaborator {
  title: string;
  description: string;
  role: 'owner' | 'editor' | 'viewer';
}
export interface MapManageFormValues {
  appSelector: string;
  isPublic: boolean;
  collaborators: Collaborator[];
}

interface MapListingProps {
  groupedMap?: GroupWithMaps[];
  refetch: () => void;
  appData?: ApplicationListDetail[];
}

export interface MapSorting {
  key: SelectOption;
  order: SelectOption;
}

export enum MAP_MANAGER_MODAL {
  DELETE_MAP_MODAL = 'DELETE_MAP_MODAL',
  COPY_MAP_MODAL = 'COPY_MAP_MODAL',
  ACCESS_CONTROL_MODAL = 'ACCESS_CONTROL_MODAL',
  CREATE_MAP_GROUP_MODAL = 'CREATE_MAP_GROUP_MODAL',
  EDIT_MAP_GROUP_MODAL = 'EDIT_MAP_GROUP_MODAL',
  DELETE_MAP_GROUP_MODAL = 'DELETE_MAP_GROUP_MODAL',
  EXPORT_MAP_MODAL = 'EXPORT_MAP_MODAL',
  FORKING_MAP_MODAL = 'FORKING_MAP_MODAL',
}
export interface MapManagerPageState {
  selectedMaps: MapData[];
  selectedMapGroup?: MapGroup;
  processing: boolean;
  modal: {
    show: boolean;
    name?: MAP_MANAGER_MODAL;
    error?: string;
  };
  showMergingView: boolean;
}

const initialState: MapManagerPageState = {
  processing: false,
  selectedMaps: [],
  modal: {
    show: false,
  },
  showMergingView: false,
};

const MapListing: React.FC<MapListingProps> = ({ groupedMap = [], refetch, appData = [] }) => {
  const { data: roles } = useFetchUserRoles();
  const { token } = useRecoilValue(userState);
  const { t, i18n } = useTranslation();
  const { teamId } = useParams<{ teamId: string }>();
  const appOptions =
    appData.map((a) => {
      return { label: a.app_name, value: a.app_key };
    }) || [];

  const { state, events, dispatch, selectors } = useMapState();
  const notification = useNotification();
  const operations = useMapOperations();

  const maps = groupedMap.reduce((acc, cur) => [...acc, ...cur.maps], [] as MapData[]);

  const getMapDataByKey = (mapKey: string) => maps.find((m) => m.map_key === mapKey);
  const getMapName = (mapId: string) => maps.find((m) => m.map_key === mapId)?.map_name || mapId;

  const getSelectedMapName = () =>
    maps.filter((m) => state.selectedMaps.includes(m.map_key)).map((m) => m.map_name);

  const selectedMapData = state.selectedMaps.map(getMapDataByKey).filter((m) => !!m) as MapData[];

  const deleteMap = async () => {
    try {
      await Promise.all(
        state.selectedMaps.map(async (m) => await deleteMapRequest(token, { map_key: m })),
      );
      notification.success(
        t('delete_map_success').replace('{val}', getSelectedMapName().join(',')),
      );
      events.clearSelectedMap();
    } catch (e) {
      notification.error(t('SOMETHING_WENT_WRONG'));
    } finally {
      refetch();
    }
  };

  const handleCopyMap = async (map: MapData) => {
    try {
      await operations.copyMap(map);
      notification.success(t('copy_map_success').replace('{val}', selectedMapName));
    } catch (e) {
      if (typeof e === 'string') {
        console.error(e);
      } else if (e instanceof Error) {
        console.error(e.message);
      }
    } finally {
      events.clearSelectedMap();
    }
  };

  const selectedMapName = selectors.getSelectedMaps().at(0)?.map_name || '';

  // const handleCopyMap = async (map: MapData) => {
  //   const mapName = map.map_name;
  //   try {
  //     await operations.copyMap(map);
  //     notification.success(t('copy_map_success').replace('{val}', mapName));
  //     events.clearSelectedMap();
  //   } catch (e) {
  //     notification.error(t('SOMETHING_WENT_WRONG'));
  //   } finally {
  //     refetch();
  //   }
  // };

  const handleShareMap = (map: MapData) => { };

  const handleDeleteGroup = (groupKey: string) => {
    dispatch(actions.selectMapGroup(groupKey));
    dispatch(actions.showModal(MAP_MANAGER_MODAL.DELETE_MAP_GROUP_MODAL));
  };

  const handleEditGroup = (groupKey: string) => {
    dispatch(actions.selectMapGroup(groupKey));
    dispatch(actions.showModal(MAP_MANAGER_MODAL.EDIT_MAP_GROUP_MODAL));
  };

  const createMapGroup = async (
    values: MapGroupRequestPayload,
    setError: UseFormReturn['setError'],
  ) => {
    dispatch(actions.processingRequest());
    try {
      await createMapGroupRequest(token, { ...values }, teamId);
      dispatch(actions.hideModal());
      notification.success(t('create_map_group_success'));
    } catch (e: any) {
      setError('groupName', {
        type: 'API',
        message: !e?.error?.response ? 'NO_RESPONSE' : e.error.response.data.error,
      });
    } finally {
      dispatch(actions.finishedRequest());
      refetch();
    }
  };

  const editMapGroup = async (
    values: MapGroupRequestPayload,
    setError: UseFormReturn['setError'],
  ) => {
    dispatch(actions.processingRequest());
    try {
      await editMapGroupRequest(token, { ...values }, teamId);
      dispatch(actions.hideModal());
      notification.success(t('update_map_group_success'));
    } catch (e: any) {
      setError('groupName', {
        type: 'API',
        message: !e?.error?.response ? 'NO_RESPONSE' : e.error.response.data.error,
      });
    } finally {
      dispatch(actions.finishedRequest());
      refetch();
    }
  };

  const deleteMapGroup = async () => {
    dispatch(actions.processingRequest());
    try {
      await deleteMapGroupRequest(token, teamId, state.selectedMapGroup);
      dispatch(actions.hideModal());
      notification.success(t('delete_map_group_success'));
    } catch (e) {
      notification.error(t('SOMETHING_WENT_WRONG'));
    } finally {
      dispatch(actions.finishedRequest());
      refetch();
    }
  };

  const assignMapToApp = async (mapData: MapData, appKeys: string[]) => {
    const unassignedApps = mapData.assigned_app_keys.filter((k) => !appKeys.includes(k));
    try {
      await assignMapToAppRequest(token, teamId, {
        assigned_app_keys: appKeys,
        map_key: mapData.map_key,
        unassigned_app_keys: unassignedApps,
      });
      notification.success(`map_setting_updated`);
    } catch (e) {
      notification.error(t('SOMETHING_WENT_WRONG'));
    } finally {
      refetch();
      dispatch(actions.hideModal());
    }
  };

  const selectedMapGroupData = groupedMap.find((g) => g.group_key === state.selectedMapGroup);

  const numberOfmaps = groupedMap.reduce((acc, cur) => {
    return acc + cur.maps.length;
  }, 0);

  return (
    <>
      <Stack>
        <Box>
          <Button
            colorScheme="blue"
            size="sm"
            variant="solid"
            onClick={events.displayCreateMapGroupModal}>
            {t('create_map_group')}
          </Button>
        </Box>
        <Stack direction="row" alignItems="flex-end" justify="space-between" spacing="16">
          <Box w="60%">
            <MapListingFilter
              {...state.filter}
              handleKeywordChange={events.handleKeywordChange}
              handleSortKeySelect={events.handleSortKeySelect}
              handleSortOrderSelect={events.handleSortOrderSelect}
              t={t}
            />
          </Box>
        </Stack>
        <MapListingTable
          filter={{
            keyword: state.filter.keyword,
            sortKey: state.filter.sortKey as MAP_MANAGER_SORT_KEY,
            sortOrder: state.filter.sortOrder as SORT_ORDER,
          }}
          groups={groupedMap}
          handleDeleteGroup={handleDeleteGroup}
          handleEditGroup={handleEditGroup}
          handleMapSelect={events.handleMapSelect}
          selectedMaps={state.selectedMaps}
          t={t}
          withCheckboxSelection={false}
          handleCopyClick={handleCopyMap}
          handleSingleMapSelect={events.handleSingleMapSelect}
        />
      </Stack>

      <CopyMap
        mapName={selectedMapName}
        show={state.modal.show && state.modal.name === MAP_MANAGER_MODAL.COPY_MAP_MODAL}
        onClose={events.hideModal}
        onConfirm={handleCopyMap}
      />
      {/* <ACLModal
        appOptions={appOptions}
        handleClose={hideModal}
        handleConfirm={assignMapToApp}
        selectedMap={getMapDataByKey(state.selectedMaps[0])!}
        show={
          !!state.selectedMaps[0] &&
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.ACCESS_CONTROL_MODAL
        }
      /> */}

      {/* <MapGroupModal
        error={state.modal.error}
        handleClose={hideModal}
        handleConfirm={createMapGroup}
        show={state.modal.show && state.modal.name === MAP_MANAGER_MODAL.CREATE_MAP_GROUP_MODAL}
        t={t}
      />

      <MapGroupModal
        data={selectedMapGroupData}
        error={state.modal.error}
        handleClose={hideModal}
        handleConfirm={editMapGroup}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.EDIT_MAP_GROUP_MODAL &&
          !!selectedMapGroupData
        }
        t={t}
      />

      <DeleteMapGroup
        groupData={selectedMapGroupData || ({} as MapGroup)}
        handleClose={hideModal}
        handleConfirm={deleteMapGroup}
        show={
          state.modal.show &&
          state.modal.name === MAP_MANAGER_MODAL.DELETE_MAP_GROUP_MODAL &&
          !!selectedMapGroupData
        }
        t={t}
      />



      <DeleteMap
        error={state.modal.error}
        mapData={selectedMapData}
        show={state.modal.show && state.modal.name === MAP_MANAGER_MODAL.DELETE_MAP_MODAL}
        onClose={hideModal}
        onConfirm={deleteMap}
      />
      <MergeMap
        selectedMaps={state.selectedMaps.map(getMapName)}
        show={state.modal.show && state.modal.name === MAP_MANAGER_MODAL.MERGE_MAP_MODAL}
        t={t}
        onClose={hideModal}
        onConfirm={mergeMap}
      />
      <ExportMap
        mapKey={state.selectedMaps.join('')}
        mapNames={getSelectedMapName()}
        show={state.modal.show && state.modal.name === MAP_MANAGER_MODAL.EXPORT_MAP_MODAL}
        t={t}
        onClose={hideModal}
        onConfirm={() => 0}
      /> */}

      {/* {!numberOfmaps && (
          <>
            <div className="mt-4 text-center text-sm">{t('no_map_in_org')}</div>
            <p className="text-center text-sm">
              {i18n.language === 'en' ? (
                <>
                  {t('please_add_new_map_with_scanner')}{' '}
                  <Link
                    external
                    to="https://docs.arcloud.pretiaar.com/3d-scanner-app/create-new-map">
                    {t('this')}
                  </Link>
                  .
                </>
              ) : (
                <>
                  <Link
                    external
                    to="https://docs.arcloud.pretiaar.com/ja/3d-scanner-app/create-new-map/">
                    {t('this')}
                  </Link>
                  {t('please_add_new_map_with_scanner')}
                </>
              )}
            </p>
          </>
        )} */}
    </>
  );
};

export default MapListing;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '@/store/atoms/users';

const UnAuthenticationRoute: React.FC = () => {
  const { isAuthenticated } = useRecoilValue(userState);
  return !isAuthenticated ? <Outlet /> : <Navigate to="/console/team" />;
};

export default UnAuthenticationRoute;

import { generatePath, Params, useParams } from 'react-router-dom';
import { TEAM_ID_KEY } from '@/routes/path';

type DeveloperConsoleURLParams = {
  teamId?: string;
};

const useURLGenerator = () => {
  const { teamId } = useParams<DeveloperConsoleURLParams>();
  const generateURL = (path: string, params?: Params) => {
    if (path.includes(TEAM_ID_KEY)) {
      return generatePath(path, { teamId, ...params });
    } else {
      return generatePath(path, { ...params });
    }
  };
  return { generateURL };
};

export default useURLGenerator;

import { DefaultValue, selector } from 'recoil';
import { TokenPayload } from '@/types/request';
import { decode } from '@/utils/token';
import preferenceState from '../preferences';
import { UserPreference } from '../preferences/types';
import { userState } from '../users';

const currentUserPreferences = selector<UserPreference>({
  key: 'preferencesSelector',
  get: ({ get }) => {
    const preferences = get(preferenceState);
    const { token } = get(userState);
    if (!token) return {};
    const decodedToken = decode<TokenPayload>(token);
    return preferences[decodedToken.user_index] || {};
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(preferenceState, newValue);
      return;
    }

    const preferences = get(preferenceState);
    const { token } = get(userState);
    if (!token) return;

    const decodedToken = decode<TokenPayload>(token);
    set(preferenceState, {
      ...preferences,
      [decodedToken.user_index]: newValue,
    });
  },
});

export default currentUserPreferences;

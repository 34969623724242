export enum MAP_MANAGER_SORT_KEY {
  NAME = 'name',
  CREATED_DATE = 'reg_dt',
  LAST_UPDATE_DATE = 'update_dt',
}

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

import Searchbox from '@/components/Input/Searchbox';
import { SelectOption } from '@/components/Select/types';
import React from 'react';
import { TFunction } from 'i18next';
import { MAP_MANAGER_SORT_KEY, SORT_ORDER } from '@/types/map';
import { mapTranslation, applyTranslation } from '@/utils/i18n/translation';
import { Box, Select, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const sortOrderOptions = [
  {
    label: 'ascending',
    value: SORT_ORDER.ASC,
  },
  {
    label: 'descending',
    value: SORT_ORDER.DESC,
  },
];

export const sortKeyOptions = [
  {
    label: 'map_name',
    value: MAP_MANAGER_SORT_KEY.NAME,
  },
  {
    label: 'created_date',
    value: MAP_MANAGER_SORT_KEY.CREATED_DATE,
  },
  {
    label: 'last_update',
    value: MAP_MANAGER_SORT_KEY.LAST_UPDATE_DATE,
  },
];

interface MapListingFilterProps {
  handleSortKeySelect: (val: MAP_MANAGER_SORT_KEY) => void;
  handleSortOrderSelect: (val: SORT_ORDER) => void;
  handleKeywordChange: (val: string) => void;
  keyword?: string;
}

const MapListingFilter: React.FC<MapListingFilterProps> = ({
  handleSortKeySelect,
  handleSortOrderSelect,
  handleKeywordChange,
  keyword,
}) => {
  const { t } = useTranslation();

  const onSortKeyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleSortKeySelect(e.target.value as MAP_MANAGER_SORT_KEY);
  };

  const onSortOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleSortOrderSelect(e.target.value as SORT_ORDER);
  };

  const onKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleKeywordChange(e.target.value);
  };

  return (
    <Stack direction="row" spacing="4" align="flex-end">
      <Box w="33%">
        <Text fontSize="xs">{t('search')}</Text>
        <Searchbox defaultValue={keyword} onChange={onKeywordChange} />
      </Box>
      <Box w="33%">
        <Text fontSize="xs">{t('sort_by')}</Text>
        <Select name="sortKey" size="sm" onChange={onSortKeyChange}>
          {sortKeyOptions.map((o) => (
            <option value={o.value} key={o.value}>
              {t(o.label)}
            </option>
          ))}
        </Select>
      </Box>
      <Box w="33%">
        <Text fontSize="xs">{t('order')}</Text>
        <Select name="sortOrder" size="sm" onChange={onSortOrderChange}>
          {sortOrderOptions.map((o) => (
            <option value={o.value} key={o.value}>
              {t(o.label)}
            </option>
          ))}
        </Select>
      </Box>
    </Stack>
  );
};

export default MapListingFilter;

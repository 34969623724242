import React from 'react';
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface LinkProps extends ChakraLinkProps, Omit<RouterLinkProps, 'color'> {
  children: React.ReactNode;
}

const Link: React.FC<LinkProps> = ({ children, ...props }) => {
  return (
    <ChakraLink {...props} as={RouterLink}>
      {children}
    </ChakraLink>
  );
};

export { Link };
export default Link;

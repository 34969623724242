import CopyField from '@/components/CopyField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Text, Button, Highlight } from '@chakra-ui/react';
import Modal from '@/components/Modal';
import { MapData } from '../../types';
import { TFunction } from 'i18next';

interface ExportMapProps {
  createShareLink: () => Promise<string>;
  t: TFunction;
  map?: MapData;
  onClose: () => void;
  show: boolean;
}

const ExportMap: React.FC<ExportMapProps> = ({ map, show, onClose, createShareLink }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [sharableLink, setShareableLink] = React.useState('');
  const { t } = useTranslation();

  const createLink = async () => {
    setIsLoading(true);
    const link = await createShareLink();
    if (link) setShareableLink(link);
    setIsLoading(false);
  };

  React.useEffect(() => {
    setShareableLink('');
  }, [show]);

  if (!map) return <></>;

  if (sharableLink && !isLoading) {
    return (
      <Modal
        footer={
          <HStack>
            <Button colorScheme="blue" size="sm" variant="solid" width="100px" onClick={onClose}>
              {t('close')}
            </Button>
          </HStack>
        }
        show={show}
        title={t('share_map')}
        onClose={onClose}>
        <CopyField content={sharableLink} label="Fork link" />
        <Box mt={4}>
          <Text fontSize="xs">
            <Highlight query={map.map_name} styles={{ fontWeight: 'bold' }}>
              {t('this_is_a_clone_link_for_map').replace('${map}', map.map_name)}
            </Highlight>
          </Text>
          <Text fontSize="xs">
            {t('map_sharing_instruction')} {t('link_will_be_expired_in24_hours')}
          </Text>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      footer={
        <HStack>
          <Button colorScheme="gray" size="sm" variant="ghost" width="100px" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            colorScheme="blue"
            size="sm"
            variant="solid"
            width="100px"
            onClick={createLink}
            isLoading={isLoading}
            loadingText={t('processing')}>
            {t('share')}
          </Button>
        </HStack>
      }
      show={show}
      title={t('share_map')}
      onClose={onClose}>
      <Text>
        <Highlight query={map.map_name} styles={{ fontWeight: 'bold' }}>
          {t('do_you_want_to_create_map_cloning_link').replace('${map}', map.map_name)}
        </Highlight>
      </Text>
      <Text color="gray.500" fontSize="xs" mb={2}>
        {t('map_share_undone_notice')}
      </Text>
    </Modal>
  );
};

export default ExportMap;
